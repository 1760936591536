import React, { useState } from "react";
import { ReactMic } from "react-mic";
import { Button, Collapse, Fab } from "@material-ui/core";
import "./record.css";
import { Edit, InfoOutlined, Mic, Stop } from "@material-ui/icons";

export default function VoiceRecorderComponent({ setOpenAudio }) {
  const [recording, setRecording] = useState(false);
  const [myAudioSrc, setMyAudioSrc] = useState(null);

  const startRecording = () => {
    setRecording(true);
  };

  const stopRecording = () => {
    setRecording(false);
  };

  const onData = (recordedBlob) => {
    console.log("chunk of real-time data is: ", recordedBlob);
  };

  const onStop = (recordedBlob) => {
    console.log("recordedBlob is: ", recordedBlob);
    var url = URL.createObjectURL(recordedBlob.blob);
    setMyAudioSrc(url);
  };

  const handleCancelRecord = () => {
    setMyAudioSrc(null);
    stopRecording();
    setOpenAudio(false);
    localStorage.setItem("showAudio", JSON.stringify(false))
  };

  /* 
    <ReactMic
      record={boolean}         // defaults -> false.  Set to true to begin recording
      pause={boolean}          // defaults -> false (available in React-Mic-Gold)
      visualSetting="sinewave" // defaults -> "sinewave".  Other option is "frequencyBars"
      className={string}       // provide css class name
      onStop={function}        // required - called when audio stops recording
      onData={function}        // optional - called when chunk of audio data is available
      onBlock={function}       // optional - called if user selected "block" when prompted to allow microphone access (available in React-Mic-Gold)
      strokeColor={string}     // sinewave or frequency bar color
      backgroundColor={string} // background color
      mimeType="audio/webm"     // defaults -> "audio/webm".  Set to "audio/wav" for WAV or "audio/mp3" for MP3 audio format (available in React-Mic-Gold)
      echoCancellation={boolean} // defaults -> false
      autoGainControl={boolean}  // defaults -> false
      noiseSuppression={boolean} // defaults -> false
      channelCount={number}     // defaults -> 2 (stereo).  Specify 1 for mono.
      bitRate={256000}          // defaults -> 128000 (128kbps).  React-Mic-Gold only.
      sampleRate={96000}        // defaults -> 44100 (44.1 kHz).  It accepts values only in range: 22050 to 96000 (available in React-Mic-Gold)
      timeSlice={3000}          // defaults -> 4000 milliseconds.  The interval at which captured audio is returned to onData callback (available in React-Mic-Gold).
    />
  */

  return (
    <div className="p-4">
      <div
        className="d-flex justify-content-between align-items-center p-2"
        style={{ backgroundColor: "#F1F6F5" }}
      >
        <h4 className="recorder-title">Contar-nos por voz</h4>
        <Button
          onClick={handleCancelRecord}
          className="audio-button-reset fw-bold"
        >
          Cancelar gravação
        </Button>
      </div>
      <div className="complaint-audio-recorder py-3 shadow-sm border">
        <div className="text-start ms-2" style={{ fontSize: 17 }}>
          Toque no microfone abaixo para começar a gravar e quando terminar,
          toque no botão com um quadradinho para enviar.
        </div>{" "}
        <br />
        <ReactMic
          record={recording}
          onStop={onStop}
          onData={onData}
          visualSetting="frequencyBars"
          strokeColor="#1C7CFF"
          backgroundColor="#FFFF"
          className="recorder-container border"
          channelCount={1}
        />
        <div className="d-flex justify-content-center my-3">
          <div>
            <Fab
              onClick={startRecording}
              color="primary"
              className={recording ? "flash-background" : ""}
            >
              <Mic />
            </Fab>{" "}
            <br />
            <h6 className="mt-2 text-dark">
              {" "}
              {recording
                ? "A gravar..."
                : myAudioSrc
                ? "Gravar novamente"
                : "Clique para iniciar"}
            </h6>
          </div>

          <div className="mx-5">
            <Fab
              onClick={stopRecording}
              color="secondary"
              disabled={!recording}
            >
              <Stop />
            </Fab>{" "}
            <br />
            <h6 className="mt-2 text-dark">Terminar</h6>
          </div>
        </div>
        <Collapse in={myAudioSrc}>
          <small
            className="text-dark p-1 px-2"
            style={{ backgroundColor: "#E8F4FD", color: "red", borderRadius: 5 }}
          >
            <InfoOutlined style={{ color: "#303F9F", fontSize: 19 }} /> Clique
            no botão triangular (play) ouvir.
          </small>
        </Collapse>
        
        <audio
          className="shadow-sm border mt-2"
          controls
          id="myAudio"
          src={myAudioSrc}
        ></audio>{" "}
      </div>
    </div>
  );
}
