import { Icon } from "@iconify/react";
import React from "react";

export default function HomeCard({ object }) {
  return (
    <div
      className={`${object.borderClass} card-home radius-10 border-start border-0 border-3`}
    >
      <div className="card-body">
        <div className="d-flex align-items-center">
          <div className="mt-2 ml-4">
            <p className={`mb-0 ${object.type === "Urgentes" ? object.textColor : "text-secondary"}`}>{object.type}</p>
            <h4 className={`${object.textColor} my-1 main-title`}>{object.value}</h4>
            <small className="mb-0 home-card-small"></small>
          </div>
          <div
            className={`${object.gradientClass} widgets-icons-2 rounded-circle text-white ms-auto`}
          >
            <Icon icon={object.icon} color="white" width="27" height="27" />
          </div>
        </div>
      </div>
    </div>
  );
}
/* 
  <div className="home-card rounded" style={{backgroundColor: object.color}}>
    <h6>{object.type}</h6>
    <h1>{object.value}</h1>
  </div> 
*/
