import React, { useEffect, useState } from "react";
import hero from "../img/kamba/hug-world.png";
import unicef from "../img/kamba/unicef.png";
import unfpda from "../img/kamba/unfpa.png";
import fdc from "../img/kamba/fdc.png";
import linhaFala from "../img/kamba/linha-fala.png";
import Appbar from "./appbar";
import { useNavigate } from "react-router-dom";
import SuggestAppName from "./modal/SuggestAppName";
import "./home.css";
import { Mic } from "@material-ui/icons";
import { Icon } from "@iconify/react";
import { Fab } from "@material-ui/core";

export default function Home() {
  let navigate = useNavigate();
  const [showSuggestion, setShowSuggestion] = useState(false);

  var showSuggestionStorage = JSON.parse(
    localStorage.getItem("showSuggestion")
  );

  useEffect(() => {
    if (showSuggestionStorage) {
      setShowSuggestion(true);
    } else {
      setShowSuggestion(true);
    }
  }, []);

  const closeSuggestion = () => {
    setShowSuggestion(false);
  };


  return (
    <div>
      <div className="container-fluid position-relative p-0">
        <Appbar />
        {/* -----------------BANNER - CAPPA--------------- */}
        <div className="container-fluid hero-header py-5 bg-info d-flex align-items-center justify-content-center">
          <div className="container pt-5">
            <div className="row align-items-center g-5">
              <div className="col-lg-6 text-center text-lg-start pt-3">
                <h1 className="display-5 text-primary animated slideInLeft text-mains">
                  Dignidade e respeito <br /> para todos, fale <br /> e{" "}
                  <span className="bold-text">seja ouvido</span>!
                </h1>
                <br />
                <blockquote className="text-primary fw-lighter animated slideInLeft mb-4 pb-2">
                  Conte-nos o que aconteceu com segurança e proteja-se! <br />{" "}
                  Sua coragem pode trazer mudanças positivas para sua vida e a
                  de outros também. Juntos, construímos um Moçambique mais
                  seguro e justo.
                </blockquote>
                <span
                  className="btn btn-primary py-sm-3 px-sm-5 me-3 animated slideInLeft"
                  onClick={() => navigate("/contar-nos")}
                >
                  Conte-nos aqui{" "}
                  <Icon
                    className="ms-2"
                    icon="lucide:notebook-pen"
                    style={{ color: "white", fontSize: 25 }}
                  />
                </span>
                <label
                  className="micro-home me-3 animated slideInLeft"
                  title="Contar-nos por áudio"
                >
                  <Fab
                    color="secondary"
                    onClick={() => {
                      navigate("/contar-nos");
                      localStorage.setItem("showAudio", JSON.stringify(true));
                    }}
                  >
                    <Mic />
                  </Fab>{" "}
                </label>
                <div
                  className="text-start mt-5 px-4 container-images"
                  style={{ width: "auto" }}
                >
                  <small> Com o apoio de:</small>
                  <div class="image-container row mt-2">
                    <div className="col-4 p-1 text-center">
                      <img src={linhaFala} alt="Unicef" width={80} />
                    </div>
                    
                    <div className="col-4 p-1 text-center">
                      <img src={unicef} alt="Unicef" width={61} />
                    </div>
                    <div className="col-4 p-1 text-center">
                      <img src={fdc} alt="Unicef" width={120} />
                    </div>
                    
                    {/* <div className="col-3 p-1 text-center">
                      <img src={unfpda} alt="Unicef" width={120} />
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 text-center text-lg-center overflow-hidden">
                <div className="hero-img py-5">
                  <img
                    src={hero}
                    alt="hero"
                    className="img-fluid"
                    style={{ width: "70%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SuggestAppName show={showSuggestion} closeModal={closeSuggestion} />
    </div>
  );
}
