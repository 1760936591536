import React, { useState } from "react";
import { Recorder } from "react-voice-recorder";
import "./record.css";
import { Button } from "@material-ui/core";

function ComplaintRecorder({setOpenAudio}) {
  const [audioDetails, setAudioDetails] = useState({
    url: null,
    blob: null,
    chunks: null,
    duration: {
      h: 0,
      m: 0,
      s: 0,
    },
  });

  const handleAudioStop = (data) => {
    console.log(data);
    setAudioDetails(data);
  };

  const handleAudioUpload = (file) => {
    console.log(file);
  };

  const handleRest = () => {
    setAudioDetails({
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    });
  };

  const handleCancelRecord = () => {
    handleRest()
    setOpenAudio(false);
  };

  return (
    <div className="complaint-recorder">
      <Recorder
        record={false}
        title={
          <div className="text-start">
            <h3 className="recorder-title">Contar-nos por voz</h3>
            <small style={{ fontSize: 17 }}>
              Toque no microfone abaixo para começar a gravar e quando terminar,
              toque no botão com um quadradinho para enviar.
            </small> <br />
            <Button onClick={handleCancelRecord} className="audio-button-reset fw-bold">
              Cancelar gravação
            </Button>
          </div>
        }
        audioURL={audioDetails.url}
        showUIAudio
        handleAudioStop={(data) => handleAudioStop(data)}
        handleAudioUpload={(data) => handleAudioUpload(data)}
        handleReset={handleRest}
        
      />
    </div>
  );
}

export default ComplaintRecorder;
