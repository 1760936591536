import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

const Confirmacao = (props) => {
  return (
    <>
      <Modal
        show={props.show}
        style={{ marginTop: 5 }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            {" "}
            <span className={props.color1} style={{ fontSize: 21 }}>
              {props.icon} {"   "}
              {props.titulo}
            </span>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <small>{props.descricao}</small>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <Button
              style={{ color: "white", textTransform: "capitalize" }}
              onClick={props.closeModal}
              className="bg-danger me-3 fw-bold"
              variant="contained"
            >
              <CancelIcon className="me-2" fontSize="small" />
              Cancelar
            </Button>
            <Button
              style={{ color: "white", textTransform: "capitalize" }}
              onClick={props.closeModal1}
              className="bg-success fw-bold"
              variant="contained"
            >
              {props.iconn}
              {props.confirmar}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Confirmacao;
