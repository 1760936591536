/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "../../../css/chart.css";
import ChartComponent from "../../charts/basicCharts";
import { estados, provincias, tiposDenuncia } from "../../utils/auxiliarData";
import { chartColors } from "../../../colors/barChartBkColor";

const ProvinciasUnioesPrematuras = ({ title, dashboardData, keyy }) => {
  var provincesKeys = [];
  var provincesData = [];

  if (dashboardData.hasOwnProperty(keyy)) {
    provincesKeys = Object.keys(dashboardData[keyy]) ?? []; // Obtém os valores das províncias
    provincesData = Object.values(dashboardData[keyy]) ?? []; // Obtém os valores das províncias
  }

  var data = {
    labels: provincesKeys,
    datasets: [
      {
        labels: provincesKeys,
        /* backgroundColor: "rgba(255, 0, 0, 1)", */
        backgroundColor: chartColors.slice(3, 14),
        borderColor: chartColors.slice(3, 14),
        borderRadius: 3,
        borderWidth: 1,
        barThickness: 25,
        data: provincesData,
      },
    ],
  };

  return (
    <ChartComponent
      title={title}
      //loader={isFetching}
      data={data}
      chartType="bar"
      indexAxis="x"
      legendPosition="top"
      showLegend={false}
    />
  );
};

export default ProvinciasUnioesPrematuras;
