import React, { useEffect } from "react";
import Appbar from "./appbar";
import denuncia from "../img/kamba/hug-world.png";
import Footer from "./footer";
import { useState } from "react";
import {
  Button,
  CircularProgress,
  Collapse,
  TextField,
} from "@material-ui/core";
import SnackBar from "./snackbar";
import {
  ThemeProvider,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import dateFormat from "dateformat";
import { Icon } from "@iconify/react";
import { Autocomplete } from "@material-ui/lab";
import {
  formasContacto,
  isUrgentOptions,
  provincias,
  tiposDenuncia,
  tiposDenunciante,
} from "./utils/auxiliarData";
import axios from "axios";
import { MicRounded, OpenInBrowser } from "@material-ui/icons";
import InfoModal from "./infoModal";
import emailjs from "@emailjs/browser";
import { BASE_URL } from "./requests/useGetData";
import ComplaintRecorder from "./voiceRecorder/RecordComplaint";
import VoiceRecorderComponent from "./audioRecorder/VoiceRecorderComponent";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FB7900", // Substitua pela cor desejada para o stepper
      color: "#FFFFFF",
    },
  },
});

const CustomAutocomplete = withStyles((theme) => ({
  root: {
    "& .MuiInput-underline:before": {
      borderBottomColor: "#E1E4E8",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "#E1E4E8",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#E1E4E8",
    },
    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "#E1E4E8",
    },
  },
}))(Autocomplete);

const today = dateFormat(new Date(), "yyyy-mm-dd");
const Formulario = () => {
  const [loader, setLoader] = useState(false);
  const [openAudio, setOpenAudio] = useState(false);
  const [open, setOpen] = useState();
  const [message, setmessage] = useState();
  const [color, setColor] = useState();

  useEffect(() => {
    var showAudio = JSON.parse(localStorage.getItem("showAudio"));
    if (showAudio) {
      setOpenAudio(showAudio);
    }
  }, [openAudio]);

  const closeSnackbar = () => {
    setOpen(false);
  };

  const [showInfo, setShowInfo] = useState(false);
  const closeInfoModal = () => {
    setShowInfo(false);
  };

  /* =============== DADOS PARA O FORMULÁRIO==================== */
  const [code, setCode] = useState("");

  /* ======= VÍTIMA ======= */
  const [nomeVit, setNomeVit] = useState("");
  const [enderecoVit, setEnderecoVit] = useState("");
  const [telefoneVit, setTelefoneVit] = useState("");

  /* ======= TESTEMUNHA ======= */
  const [nomeTest, setNomeTest] = useState("");
  const [enderecoTest, setEnderecoTest] = useState("");
  const [telefoneTest, setTelefoneTest] = useState("");

  /* ======= DENÚNCIA ======= */
  const [urgente, setUrgente] = useState("");
  const [tipoDenuncia, setTipoDenuncia] = useState();
  const [preenchedor, setPreenchedor] = useState();
  const [formaContacto, setformaContacto] = useState();
  const [dataDenuncia, setDataDenuncia] = useState(today);
  const [descricaoDenuncia, setDescricaoDenuncia] = useState("");
  const [provinvinciaDenuncia, setProvinvinciaDenuncia] = useState();
  const [distritoDenuncia, setDistritoDenuncia] = useState();

  const [showPhone, setShowPhone] = useState(false);
  const [showAddress, setShowAddress] = useState(false);

  const [errorNome, setErrorNome] = useState(false);
  const [errorNomeMessage, setErrorNomeMessage] = useState("");

  const [errorProvincia, setErrorProvincia] = useState(false);
  const [errorProvinciaMessage, setErrorProvinciaMessage] = useState("");

  const [errorTelefone, setErrorTelefone] = useState(false);
  const [errorTelefoneMessage, setErrorTelefoneMessage] = useState("");

  const [errorEndereco, setErrorEndereco] = useState(false);
  const [errorEnderecoMessage, setErrorEnderecoMessage] = useState("");

  const [errorFormContacto, setErrorFormContacto] = useState("");
  const [errorFormContactoMessage, setErrorFormContactoMessage] = useState("");
  const [errorFormContactoHelperText, setErrorFormContactoHelperText] =
    useState("");

  /*   useEffect(() => {
    if (
      formaContacto === "Ligação telefónica (Chamada)" ||
      formaContacto === "SMS (Mensagem)"
    ) {
      setShowPhone(true);
      setShowAddress(false);
    } else if (formaContacto === "Visita presencial") {
      setShowAddress(true);
      setShowPhone(false);
    } else {
      setShowAddress(false);
      setShowPhone(false);
    }
  }, [formaContacto]); */

  useEffect(() => {
    if (formaContacto === "Visita presencial") {
      setErrorFormContactoHelperText(
        "Por favor, nos diga também o seu endereço abaixo para que possamos lhe visitar."
      );
      setErrorFormContacto(false);
    } else if (formaContacto) {
      setErrorFormContacto(false);
      setErrorFormContactoHelperText("");
      setErrorFormContactoMessage("");
    } else {
      setErrorFormContactoHelperText("");
    }
  }, [formaContacto]);

  const cleanFields = () => {
    setUrgente("");
    setNomeVit("");
    setNomeTest("");
    setEnderecoVit("");
    setEnderecoTest("");
    setTelefoneVit("");
    setTelefoneTest("");
    setTipoDenuncia();
    setPreenchedor();
    setformaContacto();
    setDataDenuncia(today);
    setDescricaoDenuncia("");
    setProvinvinciaDenuncia();
    setDistritoDenuncia();
  };

  const validateContactForm = (contactForm, noTelefone, noEndereco) => {
    if (
      (contactForm === "Ligação telefónica (Chamada)" ||
        contactForm === "SMS (Mensagem)") &&
      noTelefone
    ) {
      return false;
    } else if (contactForm === "Visita presencial" && noEndereco) {
      return false;
    } else {
      return true;
    }
  };

  const formSubmit = (event) => {
    event.preventDefault();
    if (
      errorProvincia === false &&
      errorNome === false &&
      errorFormContacto === false &&
      errorTelefone === false
    ) {
      salvar();
    } else {
      console.log("Não posso salvar");
    }
  };

  const requiredFields = () => {
    if (provinvinciaDenuncia === "" || provinvinciaDenuncia === undefined) {
      setErrorProvincia(true);
      setErrorProvinciaMessage("Por favor, nos diga a sua província!");
    } else {
      setErrorProvincia(false);
      setErrorProvinciaMessage("");
    }

    if (
      (nomeVit === "" || nomeVit === undefined) &&
      (nomeTest === "" || nomeTest === undefined)
    ) {
      setErrorNome(true);
      setErrorNomeMessage("Por favor, nos diga o seu nome!");
    } else {
      setErrorNome(false);
      setErrorNomeMessage("");
    }

    if (
      (telefoneVit === "" || telefoneVit === undefined) &&
      (telefoneTest === "" || telefoneTest === undefined)
    ) {
      setErrorTelefone(true);
      setErrorTelefoneMessage("Por favor, nos diga o seu contacto!");
    } else {
      setErrorTelefone(false);
      setErrorTelefoneMessage("");
    }

    if (
      formaContacto === "" ||
      formaContacto === undefined ||
      formaContacto === null
    ) {
      setErrorFormContacto(true);
      setErrorFormContactoMessage(
        "Por favor, dê-nos o seu contacto ou endereço!"
      );
    } else {
      setErrorFormContacto(false);
      setErrorFormContactoMessage("");
    }
  };

  console.log("formaContacto", formaContacto);
  const [utilizadores, setUtilizadores] = useState();

  const fetchUsers = () => {
    axios
      .get(`${BASE_URL}/utilizadores`)
      .then((response) => {
        setUtilizadores(
          response.data.data.filter(
            (x) =>
              x.nivel_de_acesso === "Super administrador" ||
              x.nivel_de_acesso === "Administrador"
          )
        );
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  (function () {
    emailjs.init("uGmOHu_FFDIUEuHqE");
  })();

  function sendmail(idDenuncia, ref) {
    const promises = []; // Array para armazenar as promessas de envio de e-mail

    for (const user of utilizadores) {
      var emailParams = {
        to_name: user?.nome,
        tipo_denuncia: tipoDenuncia,
        provincia: provinvinciaDenuncia?.province,
        distrito: distritoDenuncia,
        id_denuncia: idDenuncia,
        to_email: user?.email,
      };

      // Adiciona a promessa de envio do e-mail ao array de promessas
      promises.push(
        emailjs.send("service_iljpxzj", "template_8qs65ux", emailParams)
      );
    }

    // Executa todas as promessas de envio de e-mail em paralelo
    Promise.all(promises)
      .then(function (results) {
        setOpen(true);
        setShowInfo(true);
        setmessage("História registada com sucesso!");
        setCode(ref);
        setColor("success");
        cleanFields();
        setLoader(false);
      })
      .catch(function (error) {
        // Ocorreu um erro no envio dos e-mails
        console.error("Erro ao enviar e-mails:", error);
      });
  }

  const salvar = () => {
    setLoader(true);
    const dados = {
      denuncia: {
        idUtilizador: null,
        preenchedor: preenchedor,
        forma_contacto: formaContacto,
        tipo_denuncia: tipoDenuncia,
        data_denuncia: dataDenuncia,
        descricao: descricaoDenuncia,
        status: "Pendente",
        provincia: provinvinciaDenuncia?.province,
        distrito: distritoDenuncia,
        urgente: urgente,
      },
      vitima: {
        nome: preenchedor === "Eu próprio" ? nomeVit : null,
        endereco: preenchedor === "Eu próprio" ? enderecoVit : null,
        telefone: preenchedor === "Eu próprio" ? telefoneVit : null,
      },
      testemunha: {
        nome: preenchedor !== "Eu próprio" ? nomeTest : null,
        endereco: preenchedor !== "Eu próprio" ? enderecoTest : null,
        telefone: preenchedor !== "Eu próprio" ? telefoneTest : null,
      },
      agressor: {
        nome: null,
      },
      prova: {
        tipo_prova: null,
      },
    };
    axios
      .post(
        `https://tivanegamestudio.co.mz/api_kamba.php/api/denuncias/store`,
        dados
      )
      .then((response) => {
        if (urgente === "Sim") {
          sendmail(response.data.data.id, response.data.data.ref);
          setOpen(true);
          setShowInfo(true);
          setmessage("História registada com sucesso!");
          setCode(response.data.data.ref);
          setColor("success");
          cleanFields();
          setLoader(false);
        } else {
          setOpen(true);
          setShowInfo(true);
          setmessage("Denúncia registada com sucesso!");
          setCode(response.data.data.ref);
          setColor("success");
          cleanFields();
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const [districts, setDistricts] = useState([]);

  const handleChangeProvince = (event, value) => {
    setDistritoDenuncia();
    setProvinvinciaDenuncia(value);
    setDistricts(value?.districts);
  };

  const handleChangeNome = (event) => {
    setNomeVit(event.target.value);
    setNomeTest(event.target.value);
    event.target.setCustomValidity("");
  };

  const handleChangeTelefone = (event) => {
    setTelefoneVit(event.target.value);
    setTelefoneTest(event.target.value);
    event.target.setCustomValidity("");
  };

  const handleChangeEndereco = (event) => {
    setEnderecoVit(event.target.value);
    setEnderecoTest(event.target.value);
  };

  const handleInvalid = (e) => {
    e.target.setCustomValidity("Por favor, preencha este campo!"); // Customiza a mensagem
  };

  return (
    <div>
      <Appbar />
      <div className="container-fluid bg-dark hero-header-denuncias">
        <div className="container text-center mt-5 pt-5 pb-4">
          <h3 className="display-6 text-white mt-4 mb-3 animated slideInDown">
            Preencha o formulário abaixo
          </h3>
          <div className="container text-center justify-content-center d-flex ">
            <p
              className="nav-link bg-primary2 fw-bold mt-3 text-light ver-mais"
              title="Filtrar"
            >
              <i>
                <small className="text-white">Conte aqui...</small>
              </i>
            </p>
          </div>
        </div>
      </div>
      {/* ----------------------LISTA DE DENUNCIAS----------------------- */}

      {/* -------------- FORMULARIO --------------- */}
      <div className="container-fluid">
        <div
          className="row d-flex justify-content-center" /* style={{ backgroundColor: "#7ce4c0" }} */
        >
          <div className="shadow py-3 col-lg-7 col-11">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
              <h3 className="mt-2">Formulário de Recolha de Dados</h3>
            </div>

            <div className="row" style={{}}>
              <div className="col-md-12 wow fadeIn" data-wow-delay="0.1s">
                <img
                  src={denuncia}
                  alt="Holding hands"
                  style={{ width: 150 }}
                />
              </div>
              <Collapse in={openAudio}>
                <VoiceRecorderComponent setOpenAudio={setOpenAudio} />
                {/* <ComplaintRecorder setOpenAudio={setOpenAudio} /> */}
              </Collapse>

              <div className="col-md-12 p-0 p-lg-3">
                <div className="wow fadeInUp" data-wow-delay="0.2s">
                  <div className="p-md-4 p-2">
                    <div
                      className="d-block d-md-flex align-items-md-center justify-content-md-between py-2"
                      style={{ backgroundColor: "#F1F6F5" }}
                    >
                      <div className="d-flex align-items-center px-2">
                        <span className="form-section-icon">1</span>
                        <h6 className="ms-2 mt-2">Conta-nos</h6>
                      </div>
                      <div className="text-start ms-2 mt-3 mt-md-0">
                        {!openAudio && (
                          <Button
                            onClick={() => {
                              setOpenAudio(true);
                              localStorage.setItem(
                                "showAudio",
                                JSON.stringify(false)
                              );
                            }}
                            className="steper-button-cont fw-bold"
                            disabled={loader}
                            startIcon={<MicRounded />}
                          >
                            Contar em áudio
                          </Button>
                        )}
                      </div>
                    </div>

                    <form
                      onSubmit={formSubmit}
                      className="border-start p-3"
                      style={{ marginLeft: 20 }}
                    >
                      <ThemeProvider theme={theme}>
                        <div className="row">
                          <div className="col-md-6" hidden={openAudio}>
                            <div className="form-floating">
                              <CustomAutocomplete
                                id="clear-on-escape"
                                options={tiposDenuncia}
                                getOptionLabel={(option) => option}
                                noOptionsText="Nenhuma opção encontrada"
                                onChange={(e, value) => setTipoDenuncia(value)}
                                value={tipoDenuncia}
                                disabled={loader}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    margin="normal"
                                    size="small"
                                    variant="standard"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        De que situação se trata?
                                      </span>
                                    }
                                    className="rounded"
                                    type={"text"}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-md-6" hidden={openAudio}>
                            <div className="form-floating">
                              <CustomAutocomplete
                                id="clear-on-escape"
                                options={isUrgentOptions}
                                getOptionLabel={(option) => option}
                                noOptionsText="Nenhuma opção encontrada"
                                onChange={(e, value) => setUrgente(value)}
                                value={urgente}
                                disabled={loader}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    margin="normal"
                                    size="small"
                                    variant="standard"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Está em risco de vida?
                                      </span>
                                    }
                                    className="rounded"
                                    type={"text"}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating">
                              <CustomAutocomplete
                                id="clear-on-escape"
                                options={tiposDenunciante}
                                getOptionLabel={(option) => option}
                                noOptionsText="Nenhuma opção encontrada"
                                onChange={(e, value) => setPreenchedor(value)}
                                value={preenchedor}
                                disabled={loader}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    margin="normal"
                                    size="small"
                                    variant="standard"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        A situação afecta a quem?
                                      </span>
                                    }
                                    className="rounded"
                                    type={"text"}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 text-start">
                            <div className="form-floating">
                              <TextField
                                variant="standard"
                                size="small"
                                margin="normal"
                                fullWidth
                                id="nomeVit"
                                name="nomeVit"
                                required
                                onInvalid={handleInvalid}
                                label={
                                  <span>
                                    <Icon
                                      icon="healthicons:megaphone"
                                      width="24"
                                      height="24"
                                      style={{
                                        marginTop: -5,
                                        color: "#fb7900",
                                      }}
                                    />{" "}
                                    Seu nome, por favor...
                                  </span>
                                }
                                type={"text"}
                                className="rounded border-bottom"
                                value={nomeVit}
                                onChange={handleChangeNome}
                                inputProps={{
                                  style: { border: "none", paddingLeft: 10 },
                                }}
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                disabled={loader}
                                error={errorNome}
                              />
                            </div>
                            {errorNome && (
                              <small style={{ color: "red" }}>
                                {errorNomeMessage}
                              </small>
                            )}
                          </div>
                          <div
                            className="col-md-6 text-start"
                            hidden={openAudio}
                          >
                            <div className="form-floating">
                              <CustomAutocomplete
                                id="clear-on-escape"
                                options={provincias}
                                getOptionLabel={(option) => option?.province}
                                noOptionsText="Nenhuma opção encontrada"
                                onChange={handleChangeProvince}
                                value={provinvinciaDenuncia}
                                disabled={loader}
                                error={errorProvincia}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    margin="normal"
                                    size="small"
                                    variant="standard"
                                    //required
                                    //onInvalid={handleInvalid}
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Província
                                      </span>
                                    }
                                    className="rounded"
                                    type={"text"}
                                  />
                                )}
                              />
                            </div>
                            {errorProvincia && (
                              <small style={{ color: "red" }}>
                                {errorProvinciaMessage}
                              </small>
                            )}
                          </div>
                          <div className="col-md-6" hidden={openAudio}>
                            <div className="form-floating">
                              <CustomAutocomplete
                                id="clear-on-escape"
                                options={districts || []}
                                getOptionLabel={(option) => option}
                                noOptionsText="Nenhuma opção encontrada"
                                onChange={(e, value) =>
                                  setDistritoDenuncia(value)
                                }
                                value={distritoDenuncia}
                                disabled={loader || !provinvinciaDenuncia}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    margin="normal"
                                    size="small"
                                    variant="standard"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Distrito
                                      </span>
                                    }
                                    className="rounded"
                                    type={"text"}
                                  />
                                )}
                              />
                            </div>
                          </div>{" "}
                          <div className={`text-start col-md-12`}>
                            <div className="form-floating">
                              <CustomAutocomplete
                                id="clear-on-escape"
                                options={formasContacto}
                                getOptionLabel={(option) => option}
                                noOptionsText="Nenhuma opção encontrada"
                                onChange={(e, value) => setformaContacto(value)}
                                value={formaContacto}
                                disabled={loader}
                                error={errorFormContacto}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    margin="normal"
                                    size="small"
                                    variant="standard"
                                    //required
                                    //onInvalid={handleInvalid}
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Forma de contacto para ajuda
                                      </span>
                                    }
                                    className="rounded"
                                    type={"text"}
                                  />
                                )}
                              />
                            </div>
                            {/* {errorFormContacto && (
                              <small style={{ color: "red" }}>
                                {errorFormContactoMessage}
                              </small>
                            )} */}
                            {errorFormContacto ? (
                              <small style={{ color: "red" }}>
                                {errorFormContactoMessage}
                              </small>
                            ) : (
                              <small style={{ color: "black" }}>
                                {errorFormContactoHelperText}
                              </small>
                            )}
                          </div>
                          <div className="col-md-6 text-start">
                            <div className="form-floating">
                              <TextField
                                variant="standard"
                                size="small"
                                margin="normal"
                                fullWidth
                                id="telefoneVit"
                                name="telefoneVit"
                                required
                                onInvalid={handleInvalid}
                                label={
                                  <span>
                                    <Icon
                                      icon="healthicons:megaphone"
                                      width="24"
                                      height="24"
                                      style={{
                                        marginTop: -5,
                                        color: "#fb7900",
                                      }}
                                    />{" "}
                                    Telefone de contacto
                                  </span>
                                }
                                type={"text"}
                                className="rounded border-bottom"
                                value={telefoneVit}
                                onChange={handleChangeTelefone}
                                inputProps={{
                                  style: { border: "none", paddingLeft: 10 },
                                }}
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                disabled={loader}
                                error={errorTelefone}
                              />
                            </div>
                            {errorTelefone && (
                              <small style={{ color: "red" }}>
                                {errorTelefoneMessage}
                              </small>
                            )}
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating">
                              <TextField
                                variant="standard"
                                size="small"
                                margin="normal"
                                fullWidth
                                id="enderecoVit"
                                name="enderecoVit"
                                label={
                                  <span>
                                    <Icon
                                      icon="healthicons:megaphone"
                                      width="24"
                                      height="24"
                                      style={{
                                        marginTop: -5,
                                        color: "#fb7900",
                                      }}
                                    />{" "}
                                    Endereço
                                  </span>
                                }
                                type={"text"}
                                className="rounded border-bottom"
                                value={enderecoVit}
                                onChange={handleChangeEndereco}
                                inputProps={{
                                  style: { border: "none", paddingLeft: 10 },
                                }}
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                disabled={loader}
                                //error={errorEndereco}
                              />
                            </div>

                            {/* {errorEndereco && (
                              <small style={{ color: "red" }}>
                                {errorEnderecoMessage}
                              </small>
                            )} */}
                          </div>
                          <div className="col-md-12 mt-3" hidden={openAudio}>
                            <div className="form-floating">
                              <TextField
                                variant="standard"
                                size="small"
                                margin="normal"
                                fullWidth
                                multiline
                                minRows={4}
                                id="descricaoDenuncia"
                                name="descricaoDenuncia"
                                label={
                                  <span>
                                    <Icon
                                      icon="healthicons:megaphone"
                                      width="24"
                                      height="24"
                                      style={{
                                        marginTop: -5,
                                        color: "#fb7900",
                                      }}
                                    />{" "}
                                    Conte para nós o que aconteceu...
                                  </span>
                                }
                                type="text"
                                className="rounded border"
                                value={descricaoDenuncia}
                                style={{ borderRadius: 10 }}
                                onChange={(e) =>
                                  setDescricaoDenuncia(e.target.value)
                                }
                                inputProps={{
                                  style: { border: "none", paddingLeft: 10 },
                                }}
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                disabled={loader}
                              />
                            </div>
                          </div>
                        </div>
                      </ThemeProvider>
                      <div className="mt-3">
                        <Button
                          onClick={cleanFields}
                          className="steper-button-ant"
                          disabled={loader}
                        >
                          Cancelar
                        </Button>

                        <Button
                          variant="contained"
                          onClick={requiredFields}
                          className="steper-button"
                          disabled={loader}
                          type="submit"
                          startIcon={
                            loader ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              <OpenInBrowser />
                            )
                          }
                        >
                          {loader ? " A registar..." : "Enviar"}
                        </Button>
                      </div>
                    </form>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*--------- FORMULARIO --------------- */}

      <InfoModal show={showInfo} closeModal={closeInfoModal} code={code} />

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom center"}
        autoHideDuration={5000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
      <Footer />
    </div>
  );
};

export default Formulario;
