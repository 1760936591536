export const getDeadLine = (date, days) => {
  // Data original
  var dataOriginal = new Date(date);

  // Calculando a nova data
  var novaData = new Date(dataOriginal);
  novaData.setDate(dataOriginal.getDate() + days);

  return novaData.toISOString().split("T")[0];
};
