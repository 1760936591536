import React from "react";
import { useNavigate } from "react-router-dom";
import page404 from "../img/page404.gif";

const NotFound = () => {
  let navigate = useNavigate();

  return (
    <div>
      <div className="container-xxl py-5">
        <h1>Page not found</h1>
        <div className="back-notfound" onClick={()=> navigate(-1)}>
         
            Voltar para anterior
       
        </div>
        <span className="bg-secondary">
          <img src={page404} alt="Page 404" style={{ width: "40%" }} />
        </span>

      </div>
    </div>
  );
};
export default NotFound;
