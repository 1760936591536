/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  TextField,
} from "@material-ui/core";
import SnackBar from "./snackbar";
import {
  ThemeProvider,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import { storage } from "../firebase";
import dateFormat from "dateformat";
import {
  CheckCircle,
  Image,
  MoreVert,
  RemoveCircle,
  Sync,
  WatchLater,
} from "@material-ui/icons";
import { Icon } from "@iconify/react";
import { Autocomplete } from "@material-ui/lab";
import {
  contextosAgressao,
  estadosCivis,
  formasContacto,
  formasResolucao,
  generos,
  grausParentesco,
  gruposAlvo,
  isUrgentOptions,
  necessidadesEspeciais,
  provincias,
  simNao,
  tiposDenuncia,
  tiposDenunciante,
} from "./utils/auxiliarData";
import axios from "axios";
import { BASE_URL } from "./requests/useGetData";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Confirmacao from "./complaintesAdmin/confirmarAccao";
import IniciarDenuncia from "./modal/AssignComplaint";
//import emailjs from "@emailjs/browser";
import { getRemainingDays } from "./utils/getRemainingDaysSurvey";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FB7900", // Substitua pela cor desejada para o stepper
      color: "#FFFFFF",
    },
  },
});

const CustomAutocomplete = withStyles((theme) => ({
  root: {
    "& .MuiInput-underline:before": {
      borderBottomColor: "#E1E4E8",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "#E1E4E8",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#E1E4E8",
    },
    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "#E1E4E8",
    },
  },
}))(Autocomplete);

const today = dateFormat(new Date(), "yyyy-mm-dd");
const EditarDenuncia = ({ refetchUrgents }) => {
  var navigate = useNavigate();
  const { id } = useParams();
  const [loadAction, setLoadAction] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [complaint, setComplaint] = useState();
  const [refetch, setRefetch] = useState(false);
  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/denuncias/show/${id}`)
      .then((response) => {
        setComplaint(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("There was an error!", error);
      });
  };
  useEffect(() => {
    fetchData();
  }, [id, refetch]);

  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState();
  const [message, setmessage] = useState();
  const [color, setColor] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const [checked, setChecked] = useState(false);
  const handleChangeChecked = (e) => setChecked(e.target.checked);

  const [showAprovar, setShowAprovar] = useState(false);
  const closeAprovar = () => {
    setShowAprovar(false);
    setChecked(false);
  };

  const [showAddUrgency, setShowAddUrgency] = useState(false);
  const closeAddUrgency = () => {
    setShowAddUrgency(false);
    setChecked(false);
  };

  const [showRemoveUrgency, setShowRemoveUrgency] = useState(false);
  const closeRemoveUrgency = () => {
    setShowRemoveUrgency(false);
    setChecked(false);
  };

  const [showProcessar, setShowProcessar] = useState(false);
  const closeProcessar = () => {
    setShowProcessar(false);
    setChecked(false);
  };

  const [showFechar, setShowFechar] = useState(false);
  const closeFechar = () => {
    setShowFechar(false);
    setChecked(false);
  };

  const [showReabrir, setShowReabrir] = useState(false);
  const closeReabrir = () => {
    setShowReabrir(false);
    setChecked(false);
  };

  const [showEliminar, setShowEliminar] = useState(false);
  const closeEliminar = () => {
    setShowEliminar(false);
    setChecked(false);
  };

  /* =============== DADOS PARA O FORMULÁRIO==================== */
  const [preenchedor, setPreenchedor] = useState();
  const [formaContacto, setformaContacto] = useState();
  /* ======= VÍTIMA ======= */
  const [nomeVit, setNomeVit] = useState();
  const [dataNascimentoVit, setDataNascimentoVit] = useState(today);
  const [generoVit, setGeneroVit] = useState();
  const [enderecoVit, setEnderecoVit] = useState();
  const [telefoneVit, setTelefoneVit] = useState();
  const [necessidadeEspVit, setNecessidadeEspVit] = useState();
  const [grupoAlvo, setGrupoAlvo] = useState();
  const [estadoCivilVit, setEstadoCivilVit] = useState();
  const [contextoAgressao, setContextoAgressao] = useState();

  /* ======= TESTEMUNHA ======= */
  const [nomeTest, setNomeTest] = useState();
  const [enderecoTest, setEnderecoTest] = useState();
  const [telefoneTest, setTelefoneTest] = useState();
  const [relacaoVitimaTest, setRelacaoVitimaTest] = useState();
  const [generoTest, setGeneroTest] = useState();
  const [dataNascimentoTest, setDataNascimentoTest] = useState(today);

  /* ======= AGRESSOR ======= */
  const [nomeAgr, setNomeAgr] = useState();
  const [dataNascimentoAgr, setDataNascimentoAgr] = useState(today);
  const [generoAgr, setGeneroAgr] = useState();
  const [enderecoAgr, setEnderecoAgr] = useState();
  const [telefoneAgr, setTelefoneAgr] = useState();
  const [relacaoVitimaAgr, setRelacaoVitimaAgr] = useState();
  const [antecedentesCriminaisAgr, setantecedentesCriminaisAgr] = useState();
  const [profissaoAgr, setProfissaoAgr] = useState();
  const [estadoCivilAgr, setEstadoCivilAgr] = useState();
  const [coabitaVitimaAgr, setCoabitaVitimaAgr] = useState();

  /* ======= PROVAS ======= */
  const [tipoProva, setTipoProva] = useState();
  const [descricao, setDescricao] = useState();

  /* ======= CICLO VIOLÊNCIA ======= */
  const [episodioAnterior, setEpisodioAnterior] = useState();
  const [violenciaAnterior, setViolenciaAnterior] = useState();
  const [formaResolucao, setFormaResolucao] = useState();
  const [observacaoesProfissional, setObservacaoesProfissional] = useState();

  /* ======= DENÚNCIA ======= */
  const [tipoDenuncia, setTipoDenuncia] = useState();
  const dataDenuncia = today;
  const [descricaoDenuncia, setDescricaoDenuncia] = useState();
  const [bairroDenuncia, setBairroDenuncia] = useState();
  const [provinvinciaDenuncia, setProvinvinciaDenuncia] = useState();
  const [distritoDenuncia, setDistritoDenuncia] = useState();

  const formSubmit = (event) => {
    event.preventDefault();
  };

  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [image1, setImage1] = useState(complaint?.prova?.arquivos);

  const handleChangeImage = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setImage1(e.target.files[0].name);
    }
  };

  const salvar = (imgLink) => {
    setLoader(true);
    const dados = {
      vitima: {
        nome: nomeVit,
        data_nascimento: dataNascimentoVit,
        genero: generoVit,
        endereco: enderecoVit,
        telefone: telefoneVit,
        nessecidades_especial: necessidadeEspVit,
      },
      testemunha: {
        nome: nomeTest,
        endereco: enderecoTest,
        telefone: telefoneTest,
        relacao_vitima: relacaoVitimaTest,
        genero: generoTest,
        data_nascimento: dataNascimentoTest,
      },
      agressor: {
        nome: nomeAgr,
        data_nascimento: dataNascimentoAgr,
        genero: generoAgr,
        endereco: enderecoAgr,
        telefone: telefoneAgr,
        relacao_vitima: relacaoVitimaAgr,
        antecedentes_criminais: antecedentesCriminaisAgr,
        profissao_agressor: profissaoAgr,
        estado_civil: estadoCivilAgr,
      },
      prova: {
        descricao: descricao,
        arquivos: imgLink,
        tipo_prova: tipoProva,
        data_criacao: today,
        nome_arquivo: image1,
      },
      denuncia: {
        idUtilizador: complaint?.idUtilizador,
        preenchedor: preenchedor,
        forma_contacto: formaContacto,
        tipo_denuncia: tipoDenuncia,
        data_denuncia: dataDenuncia,
        descricao: descricaoDenuncia,
        status: complaint?.status,
        bairro: bairroDenuncia,
        longitude: contextoAgressao,
        provincia: provinvinciaDenuncia?.province || provinvinciaDenuncia,
        distrito: distritoDenuncia,
        urgente: complaint?.urgente,
        ref: complaint?.ref,
      },
    };
    axios
      .put(
        `https://tivanegamestudio.co.mz/api_kamba.php/api/denuncias/${id}`,
        dados
      )
      .then((response) => {
        setRefetch(!refetch);
        setOpen(true);
        setmessage("Dados actualizados com sucesso!");
        setColor("success");
        //navigate("/denuncias/pendentes");
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const handleUpload = () => {
    setLoader(true);
    if (image) {
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              setImage1(image.name);
              setTipoProva(image?.type);
              salvar(url);
            });
        }
      );
    } else {
      salvar("Sem imagem");
    }
  };
  const [districts, setDistricts] = useState([]);

  const handleChangeProvince = (event, value) => {
    setDistritoDenuncia();
    setProvinvinciaDenuncia(value);
    setDistricts(value?.districts);
  };

  const [utilizadores, setUtilizadores] = useState();

  const fetchUsers = () => {
    axios
      .get(`${BASE_URL}/utilizadores`)
      .then((response) => {
        setUtilizadores(response.data.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const aprovar = () => {
    setLoadAction(true);
    const dados = {
      status: "Aprovada",
    };
    axios
      .put(`${BASE_URL}/denuncias/${complaint.id}/status`, dados)
      .then((response) => {
        setOpen(true);
        setmessage("Denúncia aprovada com sucesso!");
        setColor("success");
        setLoadAction(false);
        fetchData();
        closeAprovar();
        closeReabrir();
      })
      .catch((error) => {
        setLoadAction(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const addUrgency = () => {
    setLoadAction(true);
    const dados = {
      denuncia: {
        urgente: "Sim",
      },
    };
    axios
      .put(`${BASE_URL}/denuncias/${complaint.id}`, dados)
      .then((response) => {
        setOpen(true);
        setmessage("Denúncia marcada como URGENTE com sucesso!");
        setColor("success");
        setLoadAction(false);
        fetchData();
        closeAddUrgency();
        refetchUrgents();
      })
      .catch((error) => {
        setLoadAction(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const removeUrgency = () => {
    setLoadAction(true);
    const dados = {
      denuncia: {
        urgente: "Não",
      },
    };
    axios
      .put(`${BASE_URL}/denuncias/${complaint.id}`, dados)
      .then((response) => {
        setOpen(true);
        setmessage("Urgência retirada da denúncia com sucesso!");
        setColor("success");
        setLoadAction(false);
        fetchData();
        refetchUrgents();
        closeRemoveUrgency();
      })
      .catch((error) => {
        setLoadAction(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  /*   (function () {
    emailjs.init("CrGoWpNzqM9XsvoRZ");
  })(); */

  /*   function sendmail(assignee, prazo) {
    var emailParams = {
      to_name: assignee?.nome,
      urgente: complaint?.urgente === "Sim" ? "urgente" : "",
      tipo_denuncia: complaint.tipo_denuncia,
      provincia: complaint.provincia,
      distrito: complaint.distrito,
      id_denuncia: complaint.id,
      to_email: assignee?.email,
      prazo: dateFormat(prazo, "dd.mm.yyyy"),
    };
    emailjs
      .send("service_tggzmie", "template_2q95ped", emailParams)
      .then(function (res) {
        setOpen(true);
        setmessage("Processo da denúncia iniciado com sucesso!");
        setColor("success");
        setLoadAction(false);
        fetchData();
        closeProcessar();
      })
      .catch((error) => {
        setLoadAction(false);
        setOpen(true);
        setmessage("Ocorreu um erro ao enviar o email");
        setColor("error");
      });
  } */

  const processar = (assignee, dataInicio, prazo) => {
    setLoadAction(true);
    const dados = {
      denuncia: {
        idUtilizador: assignee?.id,
        status: "Em processo",
        data_inicio: dataInicio,
        data_fim: prazo,
      },
    };
    axios
      .put(`${BASE_URL}/denuncias/${complaint.id}`, dados)
      .then((response) => {
        //sendmail(assignee, prazo);
        setOpen(true);
        setmessage("Processo da denúncia iniciado com sucesso!");
        setColor("success");
        setLoadAction(false);
        fetchData();
        closeProcessar();
      })
      .catch((error) => {
        setLoadAction(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };
  const fechar = () => {
    setLoadAction(true);
    const dados = {
      status: "Fechada",
    };
    axios
      .put(`${BASE_URL}/denuncias/${complaint.id}/status`, dados)
      .then((response) => {
        setOpen(true);
        setmessage("Denúncia encerrada com sucesso!");
        setColor("success");
        setLoadAction(false);
        fetchData();
        closeFechar();
      })
      .catch((error) => {
        setLoadAction(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const eliminar = () => {
    setLoadAction(true);
    axios
      .delete(`${BASE_URL}/denuncias/${complaint.id}`)
      .then((response) => {
        setOpen(true);
        setmessage("Denúncia eliminada com sucesso!");
        setColor("success");
        setLoadAction(false);
        fetchData();
        closeEliminar();
        navigate(-1);
      })
      .catch((error) => {
        setLoadAction(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  function calcularIdade(dataDeNascimento) {
    var hoje = new Date();
    var dataDeNascimento = new Date(dataDeNascimento);
    var idade = hoje.getFullYear() - dataDeNascimento.getFullYear();
    var mes = hoje.getMonth() - dataDeNascimento.getMonth();

    if (mes < 0 || (mes === 0 && hoje.getDate() < dataDeNascimento.getDate())) {
      idade--;
    }

    return idade;
  }

  return (
    <div>
      {/* -------------- FORMULARIO --------------- */}
      <div className="container-fluid">
        <div
          className="row d-flex justify-content-center" /* style={{ backgroundColor: "#7ce4c0" }} */
        >
          <div className="shadow py-3 col-11">
            <div
              className="text-center wow fadeInUp my-4"
              data-wow-delay="0.1s"
            >
              <h3 className="mt-4 mb-2">Formulário de Edição de Denúncia</h3>

              <div className="text-red mb-3 fw-bold" style={{ color: "red" }}>
                {complaint?.status}
              </div>
            </div>

            {loading ? (
              <div className="text-center my-5 py-5">
                <div class="my-4 py-4">
                  <div class="my-5 py-5">
                    <div class="loader"></div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row" style={{}}>
                <div className="col-md-6 p-0 px-lg-4 d-flex justify-content-start align-items-end">
                  <div className="py-3">
                    <span className="text-dark fw-bold">Técnico Responsável: </span>
                    {complaint?.utilizador?.nome
                      ? `${complaint?.utilizador?.nome} ${
                          complaint?.utilizador?.sobrenome || ""
                        }`
                      : "---"}
                  </div>
                </div>
                <div className="col-md-6 p-0 px-lg-3">
                  {" "}
                  <div className="w-100 text-end px-2">
                    <strong
                      style={{
                        color: getRemainingDays(complaint?.data_fim).color,
                      }}
                    >
                      {complaint?.data_fim
                        ? getRemainingDays(complaint?.data_fim)?.value
                        : ""}
                    </strong>{" "}
                  </div>
                  <div className="w-100 text-end px-2 py-3">
                    <span>
                      Data e hora de submissão:
                      <strong>
                        {dateFormat(complaint?.created_at, "dd/mm/yyyy, ")}
                        às
                        {dateFormat(complaint?.created_at, " HH:MM:ss")}
                      </strong>{" "}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 p-0 px-lg-3">
                  {" "}
                  <div className="wow fadeInUp" data-wow-delay="0.2s">
                    <form onSubmit={formSubmit}>
                      <ThemeProvider theme={theme}>
                        <div className="px-2">
                          <div
                            className="d-block d-lg-flex align-items-center justify-content-between px-2"
                            style={{ backgroundColor: "#F1F6F5" }}
                          >
                            <div className="d-flex align-items-center py-1">
                              <span className="form-section-icon">1</span>
                              <h6 className="ms-2 mt-2">Dados da Denúncia</h6>
                            </div>
                            <div className="d-flex align-items-center">
                              {complaint?.status === "Pendente" ? (
                                <Button
                                  className="text-capitalize mr-3 shadow-sm px-4 border"
                                  style={{
                                    color: "#2075A9",
                                    backgroundColor: "#FFF",
                                  }}
                                  startIcon={<CheckCircle />}
                                  onClick={() => {
                                    setShowAprovar(true);
                                  }}
                                >
                                  Aprovar
                                </Button>
                              ) : (
                                <></>
                              )}
                              {complaint?.status === "Aprovada" ? (
                                <Button
                                  className="text-capitalize mr-3 shadow-sm px-3 border"
                                  style={{
                                    color: "#2075A9",
                                    backgroundColor: "#FFF",
                                  }}
                                  startIcon={<WatchLater />}
                                  onClick={() => {
                                    setShowProcessar(true);
                                  }}
                                >
                                  Iniciar processo
                                </Button>
                              ) : (
                                <></>
                              )}
                              {complaint?.status === "Em processo" ? (
                                <Button
                                  className="text-capitalize mr-3 shadow-sm px-4 border"
                                  style={{
                                    color: "#2075A9",
                                    backgroundColor: "#FFF",
                                  }}
                                  startIcon={<RemoveCircle />}
                                  onClick={() => {
                                    setShowFechar(true);
                                  }}
                                >
                                  Fechar
                                </Button>
                              ) : (
                                <></>
                              )}
                              <div className="text-end dropdownrounded">
                                <IconButton
                                  id="dropdownMenu2"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  size="small"
                                >
                                  <MoreVert style={{ color: "#2075A9" }} />
                                </IconButton>
                                <ul
                                  className="dropdown-menu shadow rounded mt-2 "
                                  aria-labelledby="dropdownMenu2"
                                >
                                  {complaint?.urgente === "Não" && (
                                    <li
                                      onClick={() => {
                                        setShowAddUrgency(true);
                                      }}
                                      className="dropdown-item"
                                    >
                                      Marcar como urgente
                                    </li>
                                  )}
                                  {complaint?.urgente === "Sim" && (
                                    <li
                                      onClick={() => {
                                        setShowRemoveUrgency(true);
                                      }}
                                      className="dropdown-item"
                                    >
                                      Retirar urgência
                                    </li>
                                  )}

                                  {complaint?.status === "Fechada" && (
                                    <li
                                      onClick={() => {
                                        setShowReabrir(true);
                                      }}
                                      className="dropdown-item"
                                    >
                                      Reabrir
                                    </li>
                                  )}
                                  <li
                                    onClick={() => {
                                      setShowEliminar(true);
                                    }}
                                    className="dropdown-item"
                                  >
                                    Eliminar
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            className="border-start p-3"
                            style={{ marginLeft: 20 }}
                          >
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <CustomAutocomplete
                                    id="clear-on-escape"
                                    options={tiposDenuncia}
                                    getOptionLabel={(option) => option}
                                    noOptionsText="Nenhuma opção encontrada"
                                    onChange={(e, value) =>
                                      setTipoDenuncia(value)
                                    }
                                    value={tipoDenuncia}
                                    defaultValue={complaint?.tipo_denuncia}
                                    disabled={loader}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        margin="normal"
                                        size="small"
                                        variant="standard"
                                        label={
                                          <span>
                                            <Icon
                                              icon="healthicons:megaphone"
                                              width="24"
                                              height="24"
                                              style={{
                                                marginTop: -5,
                                                color: "#fb7900",
                                              }}
                                            />{" "}
                                            Tipo de denúncia
                                          </span>
                                        }
                                        className="rounded"
                                        type={"text"}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <CustomAutocomplete
                                    id="clear-on-escape"
                                    options={tiposDenunciante}
                                    getOptionLabel={(option) => option}
                                    noOptionsText="Nenhuma opção encontrada"
                                    onChange={(e, value) =>
                                      setPreenchedor(value)
                                    }
                                    value={preenchedor}
                                    defaultValue={complaint?.preenchedor}
                                    disabled={loader}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        margin="normal"
                                        size="small"
                                        variant="standard"
                                        label={
                                          <span>
                                            <Icon
                                              icon="healthicons:megaphone"
                                              width="24"
                                              height="24"
                                              style={{
                                                marginTop: -5,
                                                color: "#fb7900",
                                              }}
                                            />{" "}
                                            Denunciante
                                          </span>
                                        }
                                        className="rounded"
                                        type={"text"}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <CustomAutocomplete
                                    id="clear-on-escape"
                                    options={formasContacto}
                                    getOptionLabel={(option) => option}
                                    noOptionsText="Nenhuma opção encontrada"
                                    onChange={(e, value) =>
                                      setformaContacto(value)
                                    }
                                    value={formaContacto}
                                    defaultValue={complaint?.forma_contacto}
                                    disabled={loader}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        margin="normal"
                                        size="small"
                                        variant="standard"
                                        label={
                                          <span>
                                            <Icon
                                              icon="healthicons:megaphone"
                                              width="24"
                                              height="24"
                                              style={{
                                                marginTop: -5,
                                                color: "#fb7900",
                                              }}
                                            />{" "}
                                            Forma de contacto para ajuda
                                          </span>
                                        }
                                        className="rounded"
                                        type={"text"}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <CustomAutocomplete
                                    id="clear-on-escape"
                                    options={provincias}
                                    getOptionLabel={(option) =>
                                      option?.province || option
                                    }
                                    noOptionsText="Nenhuma opção encontrada"
                                    onChange={handleChangeProvince}
                                    value={provinvinciaDenuncia}
                                    defaultValue={complaint?.provincia}
                                    disabled={loader}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        margin="normal"
                                        size="small"
                                        variant="standard"
                                        label={
                                          <span>
                                            <Icon
                                              icon="healthicons:megaphone"
                                              width="24"
                                              height="24"
                                              style={{
                                                marginTop: -5,
                                                color: "#fb7900",
                                              }}
                                            />{" "}
                                            Província
                                          </span>
                                        }
                                        className="rounded"
                                        type={"text"}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <CustomAutocomplete
                                    id="clear-on-escape"
                                    options={districts || []}
                                    getOptionLabel={(option) => option}
                                    noOptionsText="Nenhuma opção encontrada"
                                    onChange={(e, value) =>
                                      setDistritoDenuncia(value)
                                    }
                                    value={distritoDenuncia}
                                    defaultValue={complaint?.distrito}
                                    disabled={loader || !provinvinciaDenuncia}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        margin="normal"
                                        size="small"
                                        variant="standard"
                                        label={
                                          <span>
                                            <Icon
                                              icon="healthicons:megaphone"
                                              width="24"
                                              height="24"
                                              style={{
                                                marginTop: -5,
                                                color: "#fb7900",
                                              }}
                                            />{" "}
                                            Distrito
                                          </span>
                                        }
                                        className="rounded"
                                        type={"text"}
                                      />
                                    )}
                                  />
                                </div>
                              </div>{" "}
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="bairroDenuncia"
                                    name="bairroDenuncia"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Localidade
                                      </span>
                                    }
                                    type="text"
                                    className="rounded border-bottom"
                                    value={bairroDenuncia}
                                    defaultValue={complaint?.bairro}
                                    onChange={(e) =>
                                      setBairroDenuncia(e.target.value)
                                    }
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={loader}
                                  />
                                </div>
                              </div>{" "}
                              <div className="col-md-12">
                                <div className="form-floating">
                                  <CustomAutocomplete
                                    id="clear-on-escape"
                                    options={contextosAgressao || []}
                                    getOptionLabel={(option) => option}
                                    noOptionsText="Nenhuma opção encontrada"
                                    onChange={(e, value) =>
                                      setContextoAgressao(value)
                                    }
                                    value={contextoAgressao}
                                    defaultValue={complaint?.longitude}
                                    disabled={loader}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        margin="normal"
                                        size="small"
                                        variant="standard"
                                        label={
                                          <span>
                                            <Icon
                                              icon="healthicons:megaphone"
                                              width="24"
                                              height="24"
                                              style={{
                                                marginTop: -5,
                                                color: "#fb7900",
                                              }}
                                            />{" "}
                                           Em que contexto ocorreu a agressão?
                                          </span>
                                        }
                                        className="rounded"
                                        type={"text"}
                                      />
                                    )}
                                  />
                                </div>
                              </div>{" "}
                              <div className="col-md-12 mt-4">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    id="descricaoDenuncia"
                                    name="descricaoDenuncia"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Relato da denúncia
                                      </span>
                                    }
                                    type="text"
                                    className="rounded border"
                                    value={descricaoDenuncia}
                                    defaultValue={complaint?.descricao}
                                    style={{ borderRadius: 10 }}
                                    onChange={(e) =>
                                      setDescricaoDenuncia(e.target.value)
                                    }
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={loader}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                        <div className="px-2">
                          <div
                            className="d-flex align-items-center px-2"
                            style={{ backgroundColor: "#F1F6F5" }}
                          >
                            <span className="form-section-icon">2</span>
                            <h6 className="ms-2 mt-2">Vítima</h6>
                          </div>
                          <div
                            className="border-start p-3"
                            style={{ marginLeft: 20 }}
                          >
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="nomeVit"
                                    name="nomeVit"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Nome
                                      </span>
                                    }
                                    type={"text"}
                                    className="rounded border-bottom"
                                    value={nomeVit}
                                    defaultValue={complaint?.vitima?.nome}
                                    onChange={(e) => setNomeVit(e.target.value)}
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={loader}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="dataNascimentoVit"
                                    name="dataNascimentoVit"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Data de nascimento
                                      </span>
                                    }
                                    type="date"
                                    className="rounded border-bottom"
                                    value={dataNascimentoVit}
                                    defaultValue={
                                      complaint?.vitima?.data_nascimento
                                    }
                                    onChange={(e) =>
                                      setDataNascimentoVit(e.target.value)
                                    }
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={loader}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="idadeVit"
                                    name="idadeVit"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Idade (anos)
                                      </span>
                                    }
                                    type="text"
                                    className="rounded border-bottom"
                                    value={
                                      calcularIdade(dataNascimentoVit) < 0
                                        ? 0
                                        : calcularIdade(dataNascimentoVit)
                                    }
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                      //max: dateFormat(new Date(), "yyyy-mm-dd, ")
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={true}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-floating">
                                  <CustomAutocomplete
                                    id="clear-on-escape"
                                    options={generos}
                                    getOptionLabel={(option) => option}
                                    noOptionsText="Nenhuma opção encontrada"
                                    onChange={(e, value) => setGeneroVit(value)}
                                    value={generoVit}
                                    defaultValue={complaint?.vitima?.genero}
                                    disabled={loader}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        margin="normal"
                                        size="small"
                                        variant="standard"
                                        label={
                                          <span>
                                            <Icon
                                              icon="healthicons:megaphone"
                                              width="24"
                                              height="24"
                                              style={{
                                                marginTop: -5,
                                                color: "#fb7900",
                                              }}
                                            />{" "}
                                            Sexo
                                          </span>
                                        }
                                        className="rounded"
                                        type={"text"}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <CustomAutocomplete
                                    id="clear-on-escape"
                                    options={estadosCivis}
                                    getOptionLabel={(option) => option}
                                    noOptionsText="Nenhuma opção encontrada"
                                    onChange={(e, value) =>
                                      setEstadoCivilVit(value)
                                    }
                                    value={estadoCivilVit}
                                    /* defaultValue={
                                      complaint?.agressor?.estado_civil
                                    } */
                                    disabled={loader}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        margin="normal"
                                        size="small"
                                        variant="standard"
                                        label={
                                          <span>
                                            <Icon
                                              icon="healthicons:megaphone"
                                              width="24"
                                              height="24"
                                              style={{
                                                marginTop: -5,
                                                color: "#fb7900",
                                              }}
                                            />{" "}
                                            Estado civil
                                          </span>
                                        }
                                        className="rounded"
                                        type={"text"}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="telefoneVit"
                                    name="telefoneVit"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Telefone de contacto
                                      </span>
                                    }
                                    type={"text"}
                                    className="rounded border-bottom"
                                    value={telefoneVit}
                                    defaultValue={complaint?.vitima?.telefone}
                                    onChange={(e) =>
                                      setTelefoneVit(e.target.value)
                                    }
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={loader}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="enderecoVit"
                                    name="enderecoVit"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Endereço
                                      </span>
                                    }
                                    type={"text"}
                                    className="rounded border-bottom"
                                    value={enderecoVit}
                                    defaultValue={complaint?.vitima?.endereco}
                                    onChange={(e) =>
                                      setEnderecoVit(e.target.value)
                                    }
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={loader}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <CustomAutocomplete
                                    id="clear-on-escape"
                                    options={gruposAlvo}
                                    getOptionLabel={(option) => option}
                                    noOptionsText="Nenhuma opção encontrada"
                                    onChange={(e, value) => setGrupoAlvo(value)}
                                    value={grupoAlvo}
                                    /* defaultValue={
                                      complaint?.vitima?.nessecidades_especial
                                    } */
                                    disabled={loader}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        margin="normal"
                                        size="small"
                                        variant="standard"
                                        label={
                                          <span>
                                            <Icon
                                              icon="healthicons:megaphone"
                                              width="24"
                                              height="24"
                                              style={{
                                                marginTop: -5,
                                                color: "#fb7900",
                                              }}
                                            />{" "}
                                            Grupo alvo
                                          </span>
                                        }
                                        className="rounded"
                                        type={"text"}
                                      />
                                    )}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-floating">
                                  <CustomAutocomplete
                                    id="clear-on-escape"
                                    options={necessidadesEspeciais}
                                    getOptionLabel={(option) => option}
                                    noOptionsText="Nenhuma opção encontrada"
                                    onChange={(e, value) =>
                                      setNecessidadeEspVit(value)
                                    }
                                    value={necessidadeEspVit}
                                    defaultValue={
                                      complaint?.vitima?.nessecidades_especial
                                    }
                                    disabled={loader}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        margin="normal"
                                        size="small"
                                        variant="standard"
                                        label={
                                          <span>
                                            <Icon
                                              icon="healthicons:megaphone"
                                              width="24"
                                              height="24"
                                              style={{
                                                marginTop: -5,
                                                color: "#fb7900",
                                              }}
                                            />{" "}
                                            Necessidades especiais
                                          </span>
                                        }
                                        className="rounded"
                                        type={"text"}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                        <div className="px-2">
                          <div
                            className="d-flex align-items-center px-2"
                            style={{ backgroundColor: "#F1F6F5" }}
                          >
                            <span className="form-section-icon">3</span>
                            <h6 className="ms-2 mt-2">Agressor</h6>
                          </div>
                          <div
                            className="border-start p-3"
                            style={{ marginLeft: 20 }}
                          >
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="nomeAgr"
                                    name="nomeAgr"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Nome
                                      </span>
                                    }
                                    type={"text"}
                                    className="rounded border-bottom"
                                    value={nomeAgr}
                                    defaultValue={complaint?.agressor?.nome}
                                    onChange={(e) => setNomeAgr(e.target.value)}
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={loader}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="dataNascimentoAgr"
                                    name="dataNascimentoAgr"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Data de nascimento
                                      </span>
                                    }
                                    type="date"
                                    className="rounded border-bottom"
                                    value={dataNascimentoAgr}
                                    defaultValue={
                                      complaint?.agressor?.data_nascimento
                                    }
                                    onChange={(e) =>
                                      setDataNascimentoAgr(e.target.value)
                                    }
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={loader}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="idadeAgr"
                                    name="idadeAgr"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Idade (anos)
                                      </span>
                                    }
                                    type="text"
                                    className="rounded border-bottom"
                                    value={
                                      calcularIdade(dataNascimentoAgr) < 0
                                        ? 0
                                        : calcularIdade(dataNascimentoAgr)
                                    }
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                      //max: dateFormat(new Date(), "yyyy-mm-dd, ")
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <CustomAutocomplete
                                    id="clear-on-escape"
                                    options={generos}
                                    getOptionLabel={(option) => option}
                                    noOptionsText="Nenhuma opção encontrada"
                                    onChange={(e, value) => setGeneroAgr(value)}
                                    value={generoAgr}
                                    defaultValue={complaint?.agressor?.genero}
                                    disabled={loader}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        margin="normal"
                                        size="small"
                                        variant="standard"
                                        label={
                                          <span>
                                            <Icon
                                              icon="healthicons:megaphone"
                                              width="24"
                                              height="24"
                                              style={{
                                                marginTop: -5,
                                                color: "#fb7900",
                                              }}
                                            />{" "}
                                            Sexo
                                          </span>
                                        }
                                        className="rounded"
                                        type={"text"}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <CustomAutocomplete
                                    id="clear-on-escape"
                                    options={estadosCivis}
                                    getOptionLabel={(option) => option}
                                    noOptionsText="Nenhuma opção encontrada"
                                    onChange={(e, value) =>
                                      setEstadoCivilAgr(value)
                                    }
                                    value={estadoCivilAgr}
                                    defaultValue={
                                      complaint?.agressor?.estado_civil
                                    }
                                    disabled={loader}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        margin="normal"
                                        size="small"
                                        variant="standard"
                                        label={
                                          <span>
                                            <Icon
                                              icon="healthicons:megaphone"
                                              width="24"
                                              height="24"
                                              style={{
                                                marginTop: -5,
                                                color: "#fb7900",
                                              }}
                                            />{" "}
                                            Estado civil
                                          </span>
                                        }
                                        className="rounded"
                                        type={"text"}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="profissaoAgr"
                                    name="profissaoAgr"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Profissão
                                      </span>
                                    }
                                    type={"text"}
                                    className="rounded border-bottom"
                                    value={profissaoAgr}
                                    defaultValue={
                                      complaint?.agressor?.profissao_agressor
                                    }
                                    onChange={(e) =>
                                      setProfissaoAgr(e.target.value)
                                    }
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={loader}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-floating">
                                  <CustomAutocomplete
                                    id="clear-on-escape"
                                    options={isUrgentOptions}
                                    getOptionLabel={(option) => option}
                                    noOptionsText="Nenhuma opção encontrada"
                                    onChange={(e, value) =>
                                      setCoabitaVitimaAgr(value)
                                    }
                                    value={coabitaVitimaAgr}
                                    /* defaultValue={
                                      complaint?.agressor?.relacao_vitima
                                    } */
                                    disabled={loader}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        margin="normal"
                                        size="small"
                                        variant="standard"
                                        label={
                                          <span>
                                            <Icon
                                              icon="healthicons:megaphone"
                                              width="24"
                                              height="24"
                                              style={{
                                                marginTop: -5,
                                                color: "#fb7900",
                                              }}
                                            />{" "}
                                            O agressor cobita com a vítima?
                                          </span>
                                        }
                                        className="rounded"
                                        type={"text"}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="enderecoAgr"
                                    name="enderecoAgr"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Endereço
                                      </span>
                                    }
                                    type={"text"}
                                    className="rounded border-bottom"
                                    value={enderecoAgr}
                                    defaultValue={complaint?.agressor?.endereco}
                                    onChange={(e) =>
                                      setEnderecoAgr(e.target.value)
                                    }
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={loader}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="telefoneAgr"
                                    name="telefoneAgr"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Telefone de casa (ou de contacto)
                                      </span>
                                    }
                                    type={"text"}
                                    className="rounded border-bottom"
                                    value={telefoneAgr}
                                    defaultValue={complaint?.agressor?.telefone}
                                    onChange={(e) =>
                                      setTelefoneAgr(e.target.value)
                                    }
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={loader}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="antecedentesCriminaisAgr"
                                    name="antecedentesCriminaisAgr"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Antecedentes criminais
                                      </span>
                                    }
                                    type={"text"}
                                    multiline
                                    className="rounded border-bottom"
                                    value={antecedentesCriminaisAgr}
                                    defaultValue={
                                      complaint?.agressor
                                        ?.antecedentes_criminais
                                    }
                                    onChange={(e) =>
                                      setantecedentesCriminaisAgr(
                                        e.target.value
                                      )
                                    }
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={loader}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <CustomAutocomplete
                                    id="clear-on-escape"
                                    options={grausParentesco}
                                    getOptionLabel={(option) => option}
                                    noOptionsText="Nenhuma opção encontrada"
                                    onChange={(e, value) =>
                                      setRelacaoVitimaAgr(value)
                                    }
                                    value={relacaoVitimaAgr}
                                    defaultValue={
                                      complaint?.agressor?.relacao_vitima
                                    }
                                    disabled={loader}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        margin="normal"
                                        size="small"
                                        variant="standard"
                                        label={
                                          <span>
                                            <Icon
                                              icon="healthicons:megaphone"
                                              width="24"
                                              height="24"
                                              style={{
                                                marginTop: -5,
                                                color: "#fb7900",
                                              }}
                                            />{" "}
                                            Parentesco/Tipo de relação com a
                                            vítima
                                          </span>
                                        }
                                        className="rounded"
                                        type={"text"}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                        <div className="px-2">
                          <div
                            className="d-flex align-items-center px-2"
                            style={{ backgroundColor: "#F1F6F5" }}
                          >
                            <span className="form-section-icon">4</span>
                            <h6 className="ms-2 mt-2">Testemunha</h6>
                          </div>
                          <div
                            className="border-start p-3"
                            style={{ marginLeft: 20 }}
                          >
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="nomeTest"
                                    name="nomeTest"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Nome
                                      </span>
                                    }
                                    type={"text"}
                                    className="rounded border-bottom"
                                    value={nomeTest}
                                    defaultValue={complaint?.testemunha?.nome}
                                    onChange={(e) =>
                                      setNomeTest(e.target.value)
                                    }
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={loader}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-floating">
                                  <CustomAutocomplete
                                    id="clear-on-escape"
                                    options={grausParentesco}
                                    getOptionLabel={(option) => option}
                                    noOptionsText="Nenhuma opção encontrada"
                                    onChange={(e, value) =>
                                      setRelacaoVitimaTest(value)
                                    }
                                    value={relacaoVitimaTest}
                                    defaultValue={
                                      complaint?.testemunha?.relacao_vitima
                                    }
                                    disabled={loader}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        margin="normal"
                                        size="small"
                                        variant="standard"
                                        label={
                                          <span>
                                            <Icon
                                              icon="healthicons:megaphone"
                                              width="24"
                                              height="24"
                                              style={{
                                                marginTop: -5,
                                                color: "#fb7900",
                                              }}
                                            />{" "}
                                            Parentesco/Tipo de relação com a
                                            vítima
                                          </span>
                                        }
                                        className="rounded"
                                        type={"text"}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-floating">
                                  <CustomAutocomplete
                                    id="clear-on-escape"
                                    options={generos}
                                    getOptionLabel={(option) => option}
                                    noOptionsText="Nenhuma opção encontrada"
                                    onChange={(e, value) =>
                                      setGeneroTest(value)
                                    }
                                    value={generoTest}
                                    defaultValue={complaint?.testemunha?.genero}
                                    disabled={loader}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        margin="normal"
                                        size="small"
                                        variant="standard"
                                        label={
                                          <span>
                                            <Icon
                                              icon="healthicons:megaphone"
                                              width="24"
                                              height="24"
                                              style={{
                                                marginTop: -5,
                                                color: "#fb7900",
                                              }}
                                            />{" "}
                                            Sexo
                                          </span>
                                        }
                                        className="rounded"
                                        type={"text"}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="dataNascimentoTest"
                                    name="dataNascimentoTest"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Data de nascimento
                                      </span>
                                    }
                                    type="date"
                                    className="rounded border-bottom"
                                    value={dataNascimentoTest}
                                    defaultValue={
                                      complaint?.testemunha?.data_nascimento
                                    }
                                    onChange={(e) =>
                                      setDataNascimentoTest(e.target.value)
                                    }
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={loader}
                                  />
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="idadeTest"
                                    name="idadeTest"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Idade (anos)
                                      </span>
                                    }
                                    type="text"
                                    className="rounded border-bottom"
                                    value={
                                      calcularIdade(dataNascimentoTest) < 0
                                        ? 0
                                        : calcularIdade(dataNascimentoTest)
                                    }
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                      //max: dateFormat(new Date(), "yyyy-mm-dd, ")
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="enderecoTest"
                                    name="enderecoTest"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Endereço
                                      </span>
                                    }
                                    type={"text"}
                                    className="rounded border-bottom"
                                    value={enderecoTest}
                                    defaultValue={
                                      complaint?.testemunha?.endereco
                                    }
                                    onChange={(e) =>
                                      setEnderecoTest(e.target.value)
                                    }
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={loader}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="telefoneTest"
                                    name="telefoneTest"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Telefone de contacto
                                      </span>
                                    }
                                    type={"text"}
                                    className="rounded border-bottom"
                                    value={telefoneTest}
                                    defaultValue={
                                      complaint?.testemunha?.telefone
                                    }
                                    onChange={(e) =>
                                      setTelefoneTest(e.target.value)
                                    }
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={loader}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                        <div className="px-2">
                          <div
                            className="d-flex align-items-center px-2"
                            style={{ backgroundColor: "#F1F6F5" }}
                          >
                            <span className="form-section-icon">5</span>
                            <h6 className="ms-2 mt-2">Provas</h6>
                          </div>
                          <div
                            className="border-start p-3"
                            style={{ marginLeft: 20 }}
                          >
                            <div className="row">
                              <div className="col-12 text-center">
                                <img
                                  src={complaint?.prova?.arquivos}
                                  alt=""
                                  style={{ maxWidth: 500 }}
                                />
                              </div>
                              <div className="col-md-12 mt-4 mt-md-4">
                                <div className="text-center d-block d-md-flex align-items-center justify-content-center">
                                  {" "}
                                  <input
                                    className="upload-photo"
                                    id="contained-button-file"
                                    multiple
                                    onChange={handleChangeImage}
                                    type="file"
                                    disabled={loader}
                                  />
                                  <label htmlFor="contained-button-file">
                                    <Button
                                      style={{
                                        textTransform: "capitalize",
                                        color: "#24695C",
                                        backgroundColor: "#FFFF",
                                      }}
                                      className="text-center shadow-sm px-3 py-2 border"
                                      component="span"
                                      startIcon={<Image />}
                                      disabled={loader}
                                    >
                                      {" "}
                                      {!complaint?.prova?.arquivos ||
                                      complaint?.prova?.arquivos ===
                                        "Nenhum ficheiro seleccionado." ||
                                      complaint?.prova?.arquivos ===
                                        "Sem imagem"
                                        ? "Carregar "
                                        : "Alterar "}
                                      Prova
                                    </Button>
                                  </label>
                                  <div className="ms-4">
                                    <small id="file-chosen">{image1}</small>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    id="descricao"
                                    name="descricao"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Descrição
                                      </span>
                                    }
                                    type="text"
                                    className="rounded border-bottom"
                                    value={descricao}
                                    defaultValue={complaint?.prova?.descricao}
                                    onChange={(e) =>
                                      setDescricao(e.target.value)
                                    }
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={loader}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                        <div className="px-2">
                          <div
                            className="d-flex align-items-center px-2"
                            style={{ backgroundColor: "#F1F6F5" }}
                          >
                            <span className="form-section-icon">6</span>
                            <h6 className="ms-2 mt-2">Ciclo de Violência</h6>
                          </div>
                          <div
                            className="border-start p-3"
                            style={{ marginLeft: 20 }}
                          >
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-floating">
                                  <CustomAutocomplete
                                    id="clear-on-escape"
                                    options={isUrgentOptions}
                                    getOptionLabel={(option) => option}
                                    noOptionsText="Nenhuma opção encontrada"
                                    onChange={(e, value) =>
                                      setEpisodioAnterior(value)
                                    }
                                    value={episodioAnterior}
                                    //defaultValue={complaint?.tipo_denuncia}
                                    disabled={loader}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        margin="normal"
                                        size="small"
                                        variant="standard"
                                        label={
                                          <span>
                                            <Icon
                                              icon="healthicons:megaphone"
                                              width="24"
                                              height="24"
                                              style={{
                                                marginTop: -5,
                                                color: "#fb7900",
                                              }}
                                            />{" "}
                                            Já sofreu episódios de violência com
                                            o/a mesmo/a agressor/a?
                                          </span>
                                        }
                                        className="rounded"
                                        type={"text"}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-floating">
                                  <CustomAutocomplete
                                    id="clear-on-escape"
                                    options={simNao}
                                    getOptionLabel={(option) => option}
                                    noOptionsText="Nenhuma opção encontrada"
                                    onChange={(e, value) =>
                                      setViolenciaAnterior(value)
                                    }
                                    value={violenciaAnterior}
                                    //defaultValue={complaint?.preenchedor}
                                    disabled={loader}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        margin="normal"
                                        size="small"
                                        variant="standard"
                                        label={
                                          <span>
                                            <Icon
                                              icon="healthicons:megaphone"
                                              width="24"
                                              height="24"
                                              style={{
                                                marginTop: -5,
                                                color: "#fb7900",
                                              }}
                                            />{" "}
                                            Houve denúncia anterior?
                                          </span>
                                        }
                                        className="rounded"
                                        type={"text"}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-floating">
                                  <CustomAutocomplete
                                    id="clear-on-escape"
                                    options={formasResolucao}
                                    getOptionLabel={(option) => option}
                                    noOptionsText="Nenhuma opção encontrada"
                                    onChange={(e, value) =>
                                      setFormaResolucao(value)
                                    }
                                    value={formaResolucao}
                                    //defaultValue={complaint?.forma_contacto}
                                    disabled={loader}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        margin="normal"
                                        size="small"
                                        variant="standard"
                                        label={
                                          <span>
                                            <Icon
                                              icon="healthicons:megaphone"
                                              width="24"
                                              height="24"
                                              style={{
                                                marginTop: -5,
                                                color: "#fb7900",
                                              }}
                                            />{" "}
                                            Como foi resolvido o caso?
                                          </span>
                                        }
                                        className="rounded"
                                        type={"text"}
                                      />
                                    )}
                                  />
                                </div>
                              </div>

                              <div className="col-md-12 mt-4">
                                <div className="form-floating">
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    id="observacaoesProfissional"
                                    name="observacaoesProfissional"
                                    label={
                                      <span>
                                        <Icon
                                          icon="healthicons:megaphone"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginTop: -5,
                                            color: "#fb7900",
                                          }}
                                        />{" "}
                                        Observações do profissional que atendeu
                                      </span>
                                    }
                                    type="text"
                                    className="rounded border"
                                    value={observacaoesProfissional}
                                    //defaultValue={complaint?.descricao}
                                    style={{ borderRadius: 10 }}
                                    onChange={(e) =>
                                      setObservacaoesProfissional(
                                        e.target.value
                                      )
                                    }
                                    inputProps={{
                                      style: {
                                        border: "none",
                                        paddingLeft: 10,
                                      },
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={loader}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </ThemeProvider>
                      <div className="mt-3">
                        <Button
                          onClick={() => navigate("/denuncias/pendentes")}
                          className="steper-button-ant"
                          disabled={loader}
                        >
                          Voltar para lista
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleUpload}
                          className="steper-button"
                          disabled={loader}
                          startIcon={
                            loader ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              <Sync />
                            )
                          }
                        >
                          {loader ? " A enviar..." : "Salvar alterações"}
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/*--------- FORMULARIO --------------- */}

      <Confirmacao
        show={showAddUrgency}
        closeModal={closeAddUrgency}
        action={addUrgency}
        actionType={"Marcar como URGENTE"}
        objectType={`a denúncia ${complaint?.vitima?.nome ? "de" : ""}`}
        objectDescription={complaint?.vitima?.nome || ""}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      <Confirmacao
        show={showRemoveUrgency}
        closeModal={closeRemoveUrgency}
        action={removeUrgency}
        actionType={"Remover a urgência"}
        objectType={`da denúncia ${complaint?.vitima?.nome ? "de" : ""}`}
        objectDescription={complaint?.vitima?.nome || ""}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      <Confirmacao
        show={showAprovar}
        closeModal={closeAprovar}
        action={aprovar}
        actionType={"Aprovar"}
        objectType={`a denúncia ${complaint?.vitima?.nome ? "de" : ""}`}
        objectDescription={complaint?.vitima?.nome || ""}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      {/* <Confirmacao
        show={showProcessar}
        closeModal={closeProcessar}
        action={processar}
        actionType={"Iniciar processo"}
        objectType={`da denúncia ${
          complaint?.vitima?.nome ? "de" : ""
        }`}
        objectDescription={complaint?.vitima?.nome || ""}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      /> */}

      <IniciarDenuncia
        show={showProcessar}
        closeModal={closeProcessar}
        denuncia={complaint}
        action={processar}
        users={utilizadores}
        loader={loadAction}
      />

      <Confirmacao
        show={showFechar}
        closeModal={closeFechar}
        action={fechar}
        actionType={"Fechar"}
        objectType={`a denúncia ${complaint?.vitima?.nome ? "de" : ""}`}
        objectDescription={complaint?.vitima?.nome || ""}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      <Confirmacao
        show={showReabrir}
        closeModal={closeReabrir}
        action={aprovar}
        actionType={"Reabrir"}
        objectType={`a denúncia ${complaint?.vitima?.nome ? "de" : ""}`}
        objectDescription={complaint?.vitima?.nome || ""}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      <Confirmacao
        show={showEliminar}
        closeModal={closeEliminar}
        action={eliminar}
        actionType={"Eliminar"}
        objectType={`a denúncia ${complaint?.vitima?.nome ? "de" : ""}`}
        objectDescription={complaint?.vitima?.nome || ""}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom center"}
        autoHideDuration={5000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
    </div>
  );
};

export default EditarDenuncia;
