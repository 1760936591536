import React from "react";
import {
  Avatar,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { NavLink } from "react-router-dom";
import avatar from "../../img/kamba/avatar-admin.png";
import {
  Assignment,
  CheckCircleOutline,
  Comment,
  Dashboard,
  ExpandLess,
  History,
  HourglassEmpty,
  PersonAdd,
  SpeakerNotesOff,
  SupervisorAccount,
  ThumbDownAlt,
} from "@material-ui/icons";
import { Icon } from "@iconify/react";
import { useAuthUser } from "react-auth-kit";
import { organizacoes } from "../utils/auxiliarData";

export default function DrawerMenu({ classes, complaints, setShow }) {
  const auth = useAuthUser()()?.data;

  const [open, setOpen] = React.useState(true);
  const [open5, setOpen5] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClick5 = () => {
    setOpen5(!open5);
  };

  return (
    <div className="pt-5 mt-2">
      <div className="text-center py-4 pb-5 border-user">
        <div className="d-flex justify-content-center pb-3">
          <NavLink to="/dashboard">
            <Avatar
              alt={auth?.nome}
              title={auth?.nome}
              className="avatar"
              src={avatar}
            />
          </NavLink>
        </div>
        <h6 className="text-white mb-0">
          {auth?.nome ? `${auth?.nome} ${auth?.sobrenome}` : "---"}{" "}
        </h6>
        <small className="text-white text-decoration-underline">
          {auth?.organizacao}
        </small>{" "}
        <br />
        <small className="text-white">{auth?.nivel_de_acesso}</small>
        <br />
        <br />
      </div>
      <List className="mt-3">
        {/* ----------------DASHBOARD---------------- */}
        <NavLink
          className="sub-link"
          style={({ isActive }) => ({
            color: isActive ? "#FFF" : "white",
            textDecoration: isActive ? "underline" : "none",
          })}
          to={"/dashboard"}
        >
          <ListItem button className="list-item">
            <ListItemIcon>
              <Dashboard style={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText>Dashboard</ListItemText>
          </ListItem>
        </NavLink>
        {/* ----------------DENUNCIAS---------------- */}
        {auth?.organizacao === "CAI" && (
          <>
            <ListItem button className="list-item" onClick={handleClick}>
              <ListItemIcon>
                <Icon
                  icon="healthicons:megaphone"
                  width="27"
                  height="27"
                  style={{ color: "#ffff" }}
                />
              </ListItemIcon>
              <ListItemText className="text-white">Denúncias</ListItemText>
              {open ? (
                <ExpandLess className="text-white" />
              ) : (
                <ExpandMore className="text-white" />
              )}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit disablePadding>
              <List component="div" disablePadding>
                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#FFF" : "rgba(255, 255, 255, 0.7)",
                    textDecoration: isActive ? "underline" : "none",
                  })}
                  to={"/denuncias/urgentes"}
                >
                  <ListItem button className={`${classes.nested} list-item`}>
                    <ListItemIcon>
                      <HourglassEmpty
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Urgentes" />
                    <span hidden={!complaints?.length} className="urgent-badge">
                      {complaints?.length}
                    </span>
                  </ListItem>
                </NavLink>

                {(auth?.nivel_de_acesso === "Administrador" ||
                  auth?.nivel_de_acesso === "Super administrador") && (
                  <NavLink
                    className="sub-link"
                    style={({ isActive }) => ({
                      color: isActive ? "#FFF" : "rgba(255, 255, 255, 0.7)",
                      textDecoration: isActive ? "underline" : "none",
                    })}
                    to={"/denuncias/pendentes"}
                  >
                    <ListItem button className={`${classes.nested} list-item`}>
                      <ListItemIcon>
                        <HourglassEmpty
                          className="sub-link"
                          style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Pendentes" />
                    </ListItem>
                  </NavLink>
                )}

                {(auth?.nivel_de_acesso === "Administrador" ||
                  auth?.nivel_de_acesso === "Super administrador") && (
                  <NavLink
                    className="sub-link"
                    style={({ isActive }) => ({
                      color: isActive ? "#FFF" : "rgba(255, 255, 255, 0.7)",
                      textDecoration: isActive ? "underline" : "none",
                    })}
                    to={"/denuncias/aprovadas"}
                  >
                    <ListItem button className={`${classes.nested} list-item`}>
                      <ListItemIcon>
                        <CheckCircleOutline
                          className="sub-link"
                          style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Aprovadas" />
                    </ListItem>
                  </NavLink>
                )}
                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#FFF" : "rgba(255, 255, 255, 0.7)",
                    textDecoration: isActive ? "underline" : "none",
                  })}
                  to={"/denuncias/em-processo"}
                >
                  <ListItem button className={`${classes.nested} list-item`}>
                    <ListItemIcon>
                      <ThumbDownAlt
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Em processo" />
                  </ListItem>
                </NavLink>

                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#FFF" : "rgba(255, 255, 255, 0.7)",
                    textDecoration: isActive ? "underline" : "none",
                  })}
                  to={"/denuncias/fechadas"}
                >
                  <ListItem button className={`${classes.nested} list-item`}>
                    <ListItemIcon>
                      <SpeakerNotesOff
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Fechadas" />
                  </ListItem>
                </NavLink>
              </List>
            </Collapse>
            <NavLink
              className="sub-link"
              style={({ isActive }) => ({
                color: isActive ? "#FFF" : "rgba(255, 255, 255, 0.7)",
                textDecoration: isActive ? "underline" : "none",
              })}
              to={"/sugestoes"}
            >
              {(auth?.nivel_de_acesso === "Administrador" ||
                auth?.nivel_de_acesso === "Super administrador") && (
                <ListItem button className="list-item">
                  <ListItemIcon>
                    <Comment style={{ color: "#FFF", height: 25 }} />
                  </ListItemIcon>
                  <ListItemText className="text-white">
                    Sugestões de nome{" "}
                  </ListItemText>
                </ListItem>
              )}
            </NavLink>
            {(auth?.nivel_de_acesso === "Administrador" ||
              auth?.nivel_de_acesso === "Super administrador") && (
              <ListItem button className="list-item">
                <ListItemIcon>
                  <Assignment style={{ color: "#FFF", height: 25 }} />
                </ListItemIcon>
                <ListItemText className="text-white">Autoridades </ListItemText>
              </ListItem>
            )}
            {/* </NavLink> */}
            {/* ----------------UTILIZADORES---------------- */}{" "}
            {(auth?.nivel_de_acesso === "Administrador" ||
              auth?.nivel_de_acesso === "Super administrador") && (
              <ListItem button className="list-item" onClick={handleClick5}>
                <ListItemIcon>
                  <SupervisorAccount style={{ color: "#FFF", height: 25 }} />
                </ListItemIcon>
                <ListItemText className="text-white">Utilizadores</ListItemText>
                {open5 ? (
                  <ExpandLess className="text-white" />
                ) : (
                  <ExpandMore className="text-white" />
                )}
              </ListItem>
            )}
            <Collapse in={open5} timeout="auto" unmountOnExit disablePadding>
              <List component="div" disablePadding>
                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#FFF" : "rgba(255, 255, 255, 0.7)",
                    textDecoration: isActive ? "underline" : "none",
                  })}
                  to={"/utilizadores/gestao"}
                >
                  <ListItem
                    button
                    className={`${classes.nested} list-item`}
                    style={{ color: "rgba(255, 255, 255, 0.7)" }}
                  >
                    <ListItemIcon>
                      <PersonAdd
                        className="sub-link"
                        style={{
                          color: "white",
                          opacity: 0.7,
                          fontSize: 19,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Gestão" />
                  </ListItem>
                </NavLink>

                {/*             <NavLink
              className="sub-link"
              style={({ isActive }) => ({
                color: isActive ? "#FFF" : "rgba(255, 255, 255, 0.7)",
                textDecoration: isActive ? "underline" : "none",
              })}
              to={"/utilizadores/historico"}
            > */}
                <ListItem
                  button
                  className={`${classes.nested} list-item`}
                  style={{ color: "rgba(255, 255, 255, 0.7)" }}
                >
                  <ListItemIcon>
                    <History
                      className="sub-link"
                      style={{
                        color: "white",
                        opacity: 0.7,
                        fontSize: 22,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Histórico" />
                </ListItem>
                {/*  </NavLink> */}
              </List>
            </Collapse>
          </>
        )}
        {/* ----------------Terminar sessão---------------- */}
        <ListItem
          button
          className="list-item"
          onClick={() => {
            setShow(true);
          }}
        >
          <ListItemIcon>
            <Icon
              icon="ri:logout-circle-line"
              color="red"
              width="21"
              height="21"
              inline={true}
            />
          </ListItemIcon>
          <ListItemText className="text-white">Terminar sessão</ListItemText>
        </ListItem>
      </List>
    </div>
  );
}
