import React, { useEffect, useState } from "react";
import { BASE_URL } from "../requests/useGetData";
import axios from "axios";
import searchingFile from "../../img/kamba/searching1.gif";
import ComplaintsTableView from "./TableViewSuggestions";
import SuggestionsTableView from "./TableViewSuggestions";

export default function SugestoesManagement() {
  const [loading, setLoading] = useState(true);
  const [suggestions, setSuggestions] = useState([]);

  const fetchUsers = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/nomes`)
      .then((response) => {
        setSuggestions(response.data.data);
        setLoading(false);
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div>
      <div className="mt-3 border-bottom">
        <div className="d-flex justify-content-between">
          <h3>Sugestões do Nome do Aplicativo </h3>
          <div></div>
        </div>
      </div>

      <div>
        {suggestions?.length === 0 && !loading && (
          <div className="p-5 text-center d-inline-block w-100 align-items-center justify-content-center">
            <div>
              <img
                src={searchingFile}
                alt=""
                title="Nada para mostrar"
                style={{ width: "170px" }}
              />
            </div>
            <span>Nenhum resultado encontrado!</span>{" "}
          </div>
        )}
        {loading ? (
          <div className="text-center my-5 py-5">
            <div class="my-4 py-4">
              <div class="my-5 py-5">
                <div class="loader"></div>
              </div>
            </div>
          </div>
        ) : (
          <SuggestionsTableView suggestions={suggestions} />
        )}
      </div>
    </div>
  );
}
