import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo_kamba from "../img/kamba/kamba_logo.png";
import { Button, TextField } from "@material-ui/core";
import SnackBar from "./snackbar";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import { IconButton, InputAdornment } from "@material-ui/core";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import axios from "axios";
import { BASE_URL } from "./requests/useGetData";
import { useSignIn } from "react-auth-kit";
import { organizacoes } from "./utils/auxiliarData";
import unicef from "../img/kamba/unicef.png";
import unfpda from "../img/kamba/unfpa.png";
import fdc from "../img/kamba/fdc.png";
import linhaFala from "../img/kamba/linha-fala.png";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000", //your color
    },
  },
});

const Login = () => {
  const [usuario, setUsuario] = useState();
  const [senha, setSenha] = useState();
  const [errorUsuario, setErrorUsuario] = useState(false);
  const [errorUsuarioMessage, setErrorUsuarioMessage] = useState("");

  const [errorSenha, setErrorSenha] = useState(false);
  const [errorSenhaMessage, setErrorSenhaMessage] = useState("");

  let navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [open, setOpen] = useState();
  const [message, setmessage] = useState();
  const [color, setColor] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const formSubmit = (event) => {
    event.preventDefault();
    if (errorUsuario === false && errorSenha === false) {
      loggar();
    } else {
      console.log("Não posso salvar");
    }
  };

  const requiredFields = () => {
    if (usuario === "" || usuario === undefined) {
      setErrorUsuario(true);
      setErrorUsuarioMessage("Por favor, preencha este campo!");
    } else {
      setErrorUsuario(false);
      setErrorUsuarioMessage(true);
    }
    if (senha === "" || senha === undefined) {
      setErrorSenha(true);
      setErrorSenhaMessage("Por favor, preencha este campo!");
    } else {
      setErrorSenha(false);
      setErrorSenhaMessage(true);
    }
  };
  const dados = {
    usuario: usuario,
    email: usuario,
    senha: senha,
  };
  const signIn = useSignIn();
  const loggar = () => {
    setLoader(true);
    axios
      .post(`${BASE_URL}/utilizadores/login`, dados)
      .then((res) => {
        if (res.data.message === 200) {
          setLoader(false);
          signIn({
            token: true,
            authState: { data: res.data?.data?.[0] },
            tokenType: "Bearer",
            expiresIn: 43800,
          });
          if (res.data?.data?.[0]?.organizacao === "CAI") {
            navigate("/denuncias/urgentes");
          } else {
            navigate("/dashboard");
          }
        } else {
          setLoader(false);
          setOpen(true);
          setmessage("Dados incorrectos, por favor tente novamente.");
          setColor("error");
        }
      })
      .catch((error) => {
        setLoader(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };
  return (
    <div style={{ backgroundColor: "#E9F0EE" }}>
      <div class="container d-flex justify-content-center align-items-center vh-100">
        <div className="row d-flex justify-content-center">
          <div className="p-4 shadow col-10 col-md-8 col-lg-8 py-5 bg-white">
            <img src={logo_kamba} alt="Holding hands" style={{ width: 100 }} />
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
              <h5 className="section-title  text-mains text-center text-primary fw-normal ">
                Iniciar sessão
              </h5>

              <h5 className="mb-5 mt-3">Bem Vindo ao APPCAI</h5>
            </div>
            <small
              className="texter-start fw-bold"
              style={{ color: "#FB7900" }}
            >
              Introduza os seus dados de acesso para continuar:
            </small>
            <div className="wow fadeInUp mt-1" data-wow-delay="0.2s">
              <form onSubmit={formSubmit}>
                <div className="row g-3">
                  <div className="col-12">
                    <ThemeProvider theme={theme}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        fullWidth
                        name="email"
                        label="Email/Username"
                        type={"text"}
                        id="usuario"
                        value={usuario}
                        defaultValue={usuario}
                        error={errorUsuario}
                        helperText={errorUsuarioMessage}
                        onChange={(e) => setUsuario(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon style={{ color: "#007f61" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ThemeProvider>
                  </div>

                  <div className="col-12">
                    <ThemeProvider theme={theme}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        fullWidth
                        name="senha"
                        label="Senha"
                        type={showPassword ? "text" : "password"}
                        id="senha"
                        value={senha}
                        defaultValue={senha}
                        onChange={(e) => setSenha(e.target.value)}
                        error={errorSenha}
                        helperText={errorSenhaMessage}
                        autoComplete="current-password"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockIcon style={{ color: "#007f61" }} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ThemeProvider>
                  </div>

                  <div className="col-md-12 text-end">
                    <span
                      style={{
                        textDecoration: "underline",
                        color: "grey",
                        cursor: "pointer",
                      }}
                      className="link-login"
                      //onClick={() => setShow(true)}
                    >
                      <small for="senha">Recuperar senha</small>
                    </span>
                  </div>

                  {/* <div className="col-md-6 text-end">
                    <NavLink
                      to={"/registar"}
                      style={{ textDecoration: "underline", color: "grey" }}
                      className="link-login"
                    >
                      <small for="senha">Não tem conta? Registe-se!</small>
                    </NavLink>
                  </div> */}

                  <div className="col-12">
                    <Button
                      variant="contained"
                      type="submit"
                      className=" w-100 bg-primary text-light fw-bold"
                      onClick={() => requiredFields()}
                    >
                      {loader ? "A verificar" : "Entrar"}
                    </Button>
                  </div>
                </div>
              </form>
            </div>

            <div className="text-start mt-4 px-3" style={{ width: "auto" }}>
              <small> Com o apoio de:</small>
              <div class="image-container row">
                <div className="col-4 p-1 text-center">
                  <img src={linhaFala} alt="Unicef" width={80} />
                </div>
                {/* <div className="col-3 p-1 text-center">
                  <img src={unfpda} alt="Unicef" width={120} />
                </div> */}
                <div className="col-4 p-1 text-center">
                  <img src={unicef} alt="Unicef" width={61} />
                </div>
                <div className="col-4 p-1 text-center">
                  <img src={fdc} alt="Unicef" width={120} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom center"}
        autoHideDuration={5000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
    </div>
  );
};

export default Login;
