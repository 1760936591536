import React from "react";

export default function Footer() {
  return (
    <div>
      {/* ------- ------- FOOTER ------- ------- */}
      <div
        className="container-fluid text-dark footer pt-0 wow fadeIn text-start ps-0 pe-0"
        data-wow-delay="0.1s"
      >
        {" "}
        <div className="copyright">
          <div className="row g-5">
            <div className="col-12 text-center mb-0 mb-md-0">
              &copy; <span className="text-primary fw-bold">2023</span> Todos
              direitos reservados.{" "}
              {/* <!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***--> */}
              {/*                 Desenvolvido por{" "}
                {/* <a
                  className="border-bottom mail-to"
                  href="https://academiavirtual.ac.mz"
                  target="_blank"
                  rel="noreferrer"
                > *
                {"<Namba Code/>"} */}
              {/* </a> */}
              <br />
            </div>
          </div>
        </div>
      </div>
      {/* ------- ------- FOOTER ------- ------- */}
    </div>
  );
}
