import { Icon } from "@iconify/react";
import React from "react";

export default function UsersCard({ object, filter, setFilter, setPage }) {
  return (
    <div
      style={{ cursor: "pointer" }}
      className={`${
        object.borderClass
      } card-home radius-10 border-start border-0 border-3 ${
        filter===object.filter && "entity-card"
      }`}
      onClick={() => {setFilter(object.filter); setPage()}}
    >
      <div className="card-body">
        <div className="d-flex align-items-center">
          <div>
            <h4 className={`${object.textColor} my-1 main-title`}>
              {object.value()}
            </h4>
            <p className="mb-0 text-secondary">{object.type}</p>
          </div>
          <div
            className={`bg-gradient-scooter widgets-icons-2 rounded-circle text-white ms-auto`}
          >
            <Icon
              icon={object.icon}
              color="white"
              width="27"
              height="27"
              className="card-iconn"
            />
          </div>
        </div>
      </div>
    </div>
  );
}