import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { CircularProgress, LinearProgress } from "@material-ui/core";
import { BASE_URL } from "./requests/useGetData";
import HomeCard from "./cards/homeCard";
import TipoEstado from "./reports/charts/TiposVsEstados";
import ProvinciasUnioesPrematuras from "./reports/charts/TiposVsProvincias";

export default function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [loadingProvinces, setLoadingProvinces] = useState(true);
  const [dashboardData, setDashboardData] = useState();
  const [dashboardDataProvinces, setDashboardDataProvinces] = useState();

  const fetchData = () => {
    axios
      .get(`${BASE_URL}/denuncias/report/basicStatistics`)
      .then((response) => {
        setDashboardData(response.data);
        setLoading(false);
        console.log("DashboardData", response);
      })
      .catch((error) => {
        setLoading(false);
      });
    
    axios
    .get(`${BASE_URL}/denuncias/report/provinciaStatistics`)
    .then((response) => {
      setDashboardDataProvinces(response.data);
      setLoadingProvinces(false);
      console.log("DashboardDataProvinces", response);
    })
    .catch((error) => {
      setLoadingProvinces(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const data = [
    {
      title: "Uniões prematuras",
      content: [
        {
          type: "Urgentes",
          value: loading ? <CircularProgress size={20} style={{color: "#FD3550"}}/> : dashboardData?.["Uniões prematuras"]?.["urgent"],
          color: "rgb(244, 244, 244, 0.5)",
          borderClass: "border-danger",
          textColor: "text-danger",
          gradientClass: "bg-gradient-bloody",
          icon: "ant-design:alert-filled",
          route: "/denuncias/urgentes",
        },
        {
          type: "Pendentes",
          value: loading ? <CircularProgress size={20} style={{color: "#FFC107"}}/> : dashboardData?.["Uniões prematuras"]?.["pending"],
          color: "rgb(244, 231, 230)",
          borderClass: "border-warning",
          textColor: "text-warning",
          gradientClass: "bg-gradient-blooker",
          icon: "jam:alert-f",
          route: "/denuncias/pendentes",
        },
        {
          type: "Em Processo",
          value: loading ? <CircularProgress size={20} style={{color: "#15CA20"}}/> : dashboardData?.["Uniões prematuras"]?.["in_process"],
          color: "rgb(172, 205, 179, 0.5)",
          borderClass: "border-success",
          textColor: "text-success",
          gradientClass: "bg-gradient-ohhappiness",
          icon: "icon-park-outline:switching-done",
          route: "/denuncias/em-processo",
        },

        {
          type: "Encerrados",
          value: loading ? <CircularProgress size={20} style={{color: "#0DCAF0"}}/> : dashboardData?.["Uniões prematuras"]?.["closed"],
          color: "rgb(244, 244, 244, 0.5)",
          borderClass: "border-info",
          textColor: "text-info",
          gradientClass: "bg-gradient-scooter-dashboard",
          icon: "material-symbols:done-all-rounded",
          route: "/denuncias/fechadas",
        },
      ],
    },
    {
      title: "Violência contra crianças",
      content: [
        {
          type: "Urgentes",
          value: loading ? <CircularProgress size={20} style={{color: "#FD3550"}}/> : dashboardData?.["Violência contra crianças"]?.["urgent"],
          color: "rgb(244, 244, 244, 0.5)",
          borderClass: "border-danger",
          textColor: "text-danger",
          gradientClass: "bg-gradient-bloody",
          icon: "ant-design:alert-filled",
          route: "/denuncias/urgentes",
        },
        {
          type: "Pendentes",
          value: loading ? <CircularProgress size={20} style={{color: "#FFC107"}}/> : dashboardData?.["Violência contra crianças"]?.["pending"],
          color: "rgb(244, 231, 230)",
          borderClass: "border-warning",
          textColor: "text-warning",
          gradientClass: "bg-gradient-blooker",
          icon: "jam:alert-f",
          route: "/denuncias/pendentes",
        },
        {
          type: "Em Processo",
          value: loading ? <CircularProgress size={20} style={{color: "#15CA20"}}/> : dashboardData?.["Violência contra crianças"]?.["in_process"],
          color: "rgb(172, 205, 179, 0.5)",
          borderClass: "border-success",
          textColor: "text-success",
          gradientClass: "bg-gradient-ohhappiness",
          icon: "icon-park-outline:switching-done",
          route: "/denuncias/em-processo",
        },

        {
          type: "Encerrados",
          value: loading ? <CircularProgress size={20} style={{color: "#0DCAF0"}}/> : dashboardData?.["Violência contra crianças"]?.["closed"],
          color: "rgb(244, 244, 244, 0.5)",
          borderClass: "border-info",
          textColor: "text-info",
          gradientClass: "bg-gradient-scooter-dashboard",
          icon: "material-symbols:done-all-rounded",
          route: "/denuncias/fechadas",
        },
      ],
    },
    {
      title: "Violência doméstica",
      content: [
        {
          type: "Urgentes",
          value: loading ? <CircularProgress size={20} style={{color: "#FD3550"}}/> : dashboardData?.["Violência doméstica"]?.["urgent"],
          color: "rgb(244, 244, 244, 0.5)",
          borderClass: "border-danger",
          textColor: "text-danger",
          gradientClass: "bg-gradient-bloody",
          icon: "ant-design:alert-filled",
          route: "/denuncias/urgentes",
        },
        {
          type: "Pendentes",
          value: loading ? <CircularProgress size={20} style={{color: "#FFC107"}}/> : dashboardData?.["Violência doméstica"]?.["pending"],
          color: "rgb(244, 231, 230)",
          borderClass: "border-warning",
          textColor: "text-warning",
          gradientClass: "bg-gradient-blooker",
          icon: "jam:alert-f",
          route: "/denuncias/pendentes",
        },
        {
          type: "Em Processo",
          value: loading ? <CircularProgress size={20} style={{color: "#15CA20"}}/> : dashboardData?.["Violência doméstica"]?.["in_process"],
          color: "rgb(172, 205, 179, 0.5)",
          borderClass: "border-success",
          textColor: "text-success",
          gradientClass: "bg-gradient-ohhappiness",
          icon: "icon-park-outline:switching-done",
          route: "/denuncias/em-processo",
        },

        {
          type: "Encerrados",
          value: loading ? <CircularProgress size={20} style={{color: "#0DCAF0"}}/> : dashboardData?.["Violência doméstica"]?.["closed"],
          color: "rgb(244, 244, 244, 0.5)",
          borderClass: "border-info",
          textColor: "text-info",
          gradientClass: "bg-gradient-scooter-dashboard",
          icon: "material-symbols:done-all-rounded",
          route: "/denuncias/fechadas",
        },
      ],
    },
    {
      title: "Outro tipo de violência",
      content: [
        {
          type: "Urgentes",
          value: loading ? <CircularProgress size={20} style={{color: "#FD3550"}}/> : dashboardData?.["Outro tipo de violência"]?.["urgent"],
          color: "rgb(244, 244, 244, 0.5)",
          borderClass: "border-danger",
          textColor: "text-danger",
          gradientClass: "bg-gradient-bloody",
          icon: "ant-design:alert-filled",
          route: "/denuncias/urgentes",
        },
        {
          type: "Pendentes",
          value: loading ? <CircularProgress size={20} style={{color: "#FFC107"}}/> : dashboardData?.["Outro tipo de violência"]?.["pending"],
          color: "rgb(244, 231, 230)",
          borderClass: "border-warning",
          textColor: "text-warning",
          gradientClass: "bg-gradient-blooker",
          icon: "jam:alert-f",
          route: "/denuncias/pendentes",
        },
        {
          type: "Em Processo",
          value: loading ? <CircularProgress size={20} style={{color: "#15CA20"}}/> : dashboardData?.["Outro tipo de violência"]?.["in_process"],
          color: "rgb(172, 205, 179, 0.5)",
          borderClass: "border-success",
          textColor: "text-success",
          gradientClass: "bg-gradient-ohhappiness",
          icon: "icon-park-outline:switching-done",
          route: "/denuncias/em-processo",
        },

        {
          type: "Encerrados",
          value: loading ? <CircularProgress size={20} style={{color: "#0DCAF0"}}/> : dashboardData?.["Outro tipo de violência"]?.["closed"],
          color: "rgb(244, 244, 244, 0.5)",
          borderClass: "border-info",
          textColor: "text-info",
          gradientClass: "bg-gradient-scooter-dashboard",
          icon: "material-symbols:done-all-rounded",
          route: "/denuncias/fechadas",
        },
      ],
    },
  ];

  return (
    <div className="pt-3">
      <h3 className="text-start border-bottom mb-5" style={{color: "#000000"}}>Visão Geral dos Casos Registados</h3>
      {data.map((announce) => (
        <div className="my-4 text-start">
          <div className="d-flex mb-2">
            <h6 className="home-section-title main-title">{announce.title}</h6>
          </div>
          <Row>
            {announce.content.map((announce) => (
              <Col sm={3}>
                {" "}
                <Link to={announce.route}>
                  <HomeCard object={announce} />{" "}
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      ))}

      <div className="row g-4 shadow-sm rounded border p-4">
        <div className="col-12">

          <h5 className="home-section-title main-title">Vista Geral Gráfica</h5>
          {loading ? <LinearProgress className="mt-4" /> :<TipoEstado dashboardData={dashboardData}/>}
        </div>
      </div>

      <div className="row g-4 shadow-sm rounded border p-4 mt-4">
        <div className="col-12">
          <h5 className="home-section-title main-title">Relatório Provincial</h5>
        </div>

        {
          loadingProvinces ? <LinearProgress className="mt-4" /> :
          dashboardData ? Object?.keys(dashboardData)?.map((key, value) => (
            <div className="col-12">
              <ProvinciasUnioesPrematuras title={`Casos de ${key}`} keyy={key} dashboardData={dashboardDataProvinces}/>
              <br />
            </div>
          )) : null
        }
      </div>

    </div>
  );
}
