import React from "react";
import "@splidejs/splide/dist/css/splide.min.css";
import "./App.css";
import "./css/style.css";
import "./css/cards-style.css";
import "./css/pagination.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "./css/bootstrap.min.css";
import "react-voice-recorder/dist/index.css";
import Routing from "./routes";

const App = () => {
  return (
    <div className="App container-fluid p-0">
      <Routing />
    </div>
  );
};

export default App;
