const niveisAcesso = ["Super administrador", "Administrador", "Responsável"];
const organizacoes = ["Linha Fala Criança", "UNFPA", "UNICEF", "FDC", "CAI"];

const tiposDenuncia = [
  "Uniões prematuras",
  "Violência contra crianças",
  "Violência doméstica",
  "Outro tipo de violência",
];

const isUrgentOptions = ["Sim", "Não"];
const simNao = ["Sim", "Não", "N/A"];

const tiposDenunciante = ["Eu próprio", "Outra pessoa"];

const formasContacto = [
  "Ligação telefónica (Chamada)",
  "SMS (Mensagem)",
  "Visita presencial",
];

const formasResolucao = [
  "Condenado",
  "Absolvido",
  "Desistência",
  "Sem informação",
  "N/A",
];

const contextosAgressao = [
  "Resposta a emergência",
  "Programa de ajuda ao desenvolvimento"
]

const grausParentesco = [
  "Pai",
  "Mãe",
  "Padrasto",
  "Madrasta",
  "Filho",
  "Filha",
  "Irmão",
  "Irmã",
  "Avô",
  "Avó",
  "Neto",
  "Neta",
  "Tio",
  "Tia",
  "Sobrinho",
  "Sobrinha",
  "Primo",
  "Prima",
  "Amigo",
  "Amiga",
  "Cônjuge",
  "Namorado",
  "Namorada",
];

const generos = ["Masculino", "Feminino", "Outro"];

const estados = ["Urgentes", "Pendentes", "Em Processo", "Encerrados"];

const estadosCivis = [
  "Solteiro(a)",
  "Casado(a)",
  "Divorciado(a)",
  "Viúvo(a)",
  "Separado(a)",
  "União Estável",
];

const necessidadesEspeciais = [
  "Deficiência visual",
  "Deficiência auditiva",
  "Deficiência física",
  "Deficiência intelectual",
  "Transtorno do espectro autista",
  "Síndrome de Down",
  "Transtorno de déficit de atenção e hiperatividade (TDAH)",
  "Transtornos psicossociais",
  "Transtornos de aprendizagem",
  "Outras necessidades especiais",
];

const gruposAlvo = [
  "Criança",
  "Pessoa com Deficiência",
  "Idoso",
  "Toxicodependentes",
  "Outros",
];

const provincias = [
  {
    id: 1,
    province: "Cabo Delgado",
    districts: [
      "Ancuabe",
      "Balama",
      "Chiúre",
      "Ibo",
      "Macomia",
      "Mecúfi",
      "Meluco",
      "Metuge",
      "Mocímboa da Praia",
      "Montepuez",
      "Mueda",
      "Muidumbe",
      "Namuno",
      "Nangade",
      "Palma",
      "Pemba",
      "Quissanga",
    ],
  },
  {
    id: 2,
    province: "Niassa",
    districts: [
      "Chimbonila",
      "Cuamba",
      "Lago",
      "Lichinga",
      "Majune",
      "Mandimba",
      "Marrupa",
      "Maúa",
      "Mavago",
      "Mecanhelas",
      "Mecula",
      "Metarica",
      "Muembe",
      "N'gauma",
      "Nipepe",
      "Sanga",
    ],
  },
  {
    id: 3,
    province: "Nampula",
    districts: [
      "Angoche",
      "Eráti",
      "Ilha de Moçambique",
      "Lalaua",
      "Larde",
      "Liúpo",
      "Malema",
      "Meconta",
      "Mecubúri",
      "Memba",
      "Mogincual",
      "Mogovolas",
      "Moma",
      "Monapo",
      "Mossuril",
      "Muecate",
      "Murrupula",
      "Nacala-a-Velha",
      "Nacala Porto",
      "Nacarôa",
      "Nampula",
      "Rapale",
      "Ribaué",
    ],
  },
  {
    id: 4,
    province: "Zambézia",
    districts: [
      "Alto Molócue",
      "Chinde",
      "Derre",
      "Gilé",
      "Gurué",
      "Ile",
      "Inhassunge",
      "Luabo",
      "Lugela",
      "Maganja da Costa",
      "Milange",
      "Mocuba",
      "Mocubela",
      "Molumbo",
      "Mopeia",
      "Morrumbala",
      "Mulevala",
      "Namacurra",
      "Namarroi",
      "Nicoadala",
      "Pebane",
      "Quelimane",
    ],
  },
  {
    id: 5,
    province: "Tete",
    districts: [
      "Angónia",
      "Cahora-Bassa",
      "Changara",
      "Chifunde",
      "Chiuta",
      "Dôa",
      "Macanga",
      "Magoé",
      "Marara",
      "Marávia",
      "Moatize",
      "Mutarara",
      "Tete",
      "Tsangano",
      "Zumbo",
    ],
  },
  {
    id: 6,
    province: "Manica",
    districts: [
      "Bárue",
      "Chimoio",
      "Gondola",
      "Guro",
      "Macate",
      "Machaze",
      "Macossa",
      "Manica",
      "Mossurize",
      "Sussundenga",
      "Tambara",
      "Vanduzi",
    ],
  },
  {
    id: 7,
    province: "Sofala",
    districts: [
      "Beira",
      "Búzi",
      "Caia",
      "Chemba",
      "Cheringoma",
      "Chibabava",
      "Dondo",
      "Gorongosa",
      "Machanga",
      "Maringué",
      "Marromeu",
      "Muanza",
      "Nhamatanda",
    ],
  },
  {
    id: 8,
    province: "Inhambane",
    districts: [
      "Funhalouro",
      "Govuro",
      "Homoíne",
      "Inhambane",
      "Inharrime",
      "Inhassoro",
      "Jangamo",
      "Mabote",
      "Massinga",
      "Maxixe",
      "Morrumbene",
      "Panda",
      "Vilanculos",
      "Zavala",
    ],
  },
  {
    id: 9,
    province: "Gaza",
    districts: [
      "Bilene",
      "Chibuto",
      "Chicualacuala",
      "Chigubo",
      "Chókwè",
      "Chongoene",
      "Guijá",
      "Limpopo",
      "Mabalane",
      "Manjacaze",
      "Mapai",
      "Massangena",
      "Massingir",
      "Xai-Xai",
    ],
  },
  {
    id: 10,
    province: "Maputo Província",
    districts: [
      "Boane",
      "Magude",
      "Manhiça",
      "Marracuene",
      "Matola",
      "Matutuíne",
      "Moamba",
      "Namaacha",
    ],
  },
  {
    id: 11,
    province: "Maputo Cidade",
    districts: [
      "KaMpfumo",
      "Nlhamankulu",
      "KaMaxaquene",
      "KaMavota",
      "KaMubukwana",
      "KaTembe",
      "KaNyaka",
    ],
  },
];

export {
  niveisAcesso,
  organizacoes,
  tiposDenuncia,
  grausParentesco,
  generos,
  estados,
  estadosCivis,
  necessidadesEspeciais,
  gruposAlvo,
  provincias,
  tiposDenunciante,
  formasContacto,
  formasResolucao,
  isUrgentOptions,
  simNao,
  contextosAgressao,
};
