import React, { useEffect } from "react";
import Perfil from "../../../components/perfilAdmin";
import AppbarDrawer from "../../../components/drawer/drawer";



const PerfilAdminPag = () => {
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }, []);
  return (
    <AppbarDrawer>
      <Perfil />
    </AppbarDrawer>
  );
};
export default PerfilAdminPag;

