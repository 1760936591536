import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import dateFormat from "dateformat";

export default function SuggestionsTableView({ suggestions }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <TableContainer component={"div"} className="bg-white shadow my-5">
      {/* -------------------TABELA DE LISTAGEM------------- */}

      <Table aria-label="simple table">
        <TableHead className="table-head">
          <TableRow>
            <TableCell className="fw-bold">Código</TableCell>
            <TableCell className="fw-bold">Nome sugerido</TableCell>
            <TableCell className="fw-bold">Data de registo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {suggestions
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((suggestion) => (
              <TableRow key={suggestion?.id} className={"table-row-simple"}>
                <TableCell component="th" scope="row" className="fw-bold">
                  {suggestion?.id || "---"}
                </TableCell>
                <TableCell>{suggestion?.descricao || "---"}</TableCell>
                <TableCell>
                  {dateFormat(suggestion?.created_at, "dd/mm/yyyy, ")}
                  às
                  {dateFormat(suggestion?.created_at, " HH:MM")}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={suggestions?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={"Linhas por página"}
      />
    </TableContainer>
  );
}
