import React from "react";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import AdminPag from "./pages/administrador";
import FormularioDenuncias from "./pages/registarDenuncia";
import HomePage from "./pages/home";
import LoginPag from "./pages/login";
import PerfilAdminPag from "./pages/utilizadores/perfilAdmin";
import NotFoundPage from "./pages/notfound";
import GestaoUtilizadoresPag from "./pages/utilizadores/academia";
import EditarDenunciaPag from "./pages/editarDenuncia";
import ComplaintsPage from "./pages/denuncias/lista";
import RequireAuth from "./RequireAuth";
import SugestoesPag from "./pages/sugestoes";

const Routing = () => {
  return (
    <Routes>
      <Route exact path="/denuncias/:estado" element={<ComplaintsPage />} />
      <Route exact path="/dashboard" element={<AdminPag />} />
      <Route exact path="/perfilAdmin" element={<PerfilAdminPag />} />
      <Route exact path="/sugestoes" element={<SugestoesPag />} />
      <Route exact path="/utilizadores/gestao" element={<GestaoUtilizadoresPag />} />
      <Route exact path="/editarDenuncia/:id" element={<EditarDenunciaPag />} />

      <Route exact path="/" element={<Navigate replace to={'home'} />} />

      <Route exact path="*" element={<NotFoundPage />} />
      <Route exact path="/home" element={<HomePage />} />
      <Route exact path="/login" element={<LoginPag />} />
      <Route exact path="/contar-nos" element={<FormularioDenuncias />} />
    </Routes>
  );
};

export default Routing;
