import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Collapse,
  FormHelperText,
  IconButton,
  InputAdornment,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  withStyles,
} from "@material-ui/core";
import {
  Block,
  ClearAll,
  Email,
  Lock,
  MoreVert,
  Person,
  PersonAdd,
  Save,
  Search,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import nothing22 from "../../img/nothingWaw.gif";
import { Col, Row, Spinner } from "react-bootstrap";
import { useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import { BASE_URL } from "../requests/useGetData";
import UsersCard from "../cards/usersCard";
import Confirmacao from "../modal/confirmarOperacao";
import SnackBar from "../snackbar";
import {
  generos,
  niveisAcesso,
  organizacoes,
  provincias,
} from "../utils/auxiliarData";
import dateFormat from "dateformat";
import { useAuthUser } from "react-auth-kit";

const CustomAutocomplete = withStyles((theme) => ({
  root: {
    "& .MuiInput-underline:before": {
      borderBottomColor: "#FFFF",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "#FFFF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FFFF",
    },
    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "#E1E4E8",
    },
  },
}))(Autocomplete);

const today = dateFormat(new Date(), "yyyy-mm-dd");
export default function UsersManagement() {
  const auth = useAuthUser()()?.data;

  const [loading, setLoading] = useState(true);
  const [loadingTotal, setLoadingTotal] = useState(true);
  const [loader, setLoader] = useState(false);
  const [loadAction, setLoadAction] = useState(false);
  const [editing, setEditing] = useState(false);
  const [totalUsers, setTotalUsers] = useState();

  const fetchTotalUsers = () => {
    axios
      .get(`${BASE_URL}/utilizadores`)
      .then((response) => {
        setTotalUsers(response.data.data);
        setLoadingTotal(false);
      })
      .catch((error) => {
        setLoadingTotal(false);
      });
  };
  useEffect(() => {
    fetchTotalUsers();
  }, []);

  const [showForm, setShowForm] = useState(false);
  const [filter, setFilter] = useState("");
  const [utilizadores, setUtilizadores] = useState();

  const [nomeUser, setNomeUser] = useState("");

  const getParams = () => {
    var paramss = "";
    if (nomeUser) {
      paramss += `?nome=${nomeUser}`;
    }
    if (filter) {
      paramss += `?role=${filter}`;
    }
    return paramss;
  };

  const resetFilters = () => {
    setNomeUser("");
    setFilter("");
  };

  const fetchUsers = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/utilizadores`)
      .then((response) => {
        setUtilizadores(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  /* --------------------DADOS DO FORMULÁRIO---------------------- */
  const [userId, setUserId] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [nome, setNome] = useState("");
  const [apelido, setApelido] = useState("");
  const [dataNascimento, setDataNascimento] = useState(today);
  const [contacto, setContacto] = useState("");
  const [genero, setGenero] = useState({});
  const [endereco, setEndereco] = useState();
  const [provincia, setProvincia] = useState({});
  const [distrito, setDistrito] = useState({});
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [tipo, setTipo] = useState({});
  const [organizacao, setOrganizacao] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const [errorNome, setErrorNome] = useState(false);
  const [errorNomeMessage, setErrorNomeMessage] = useState("");

  const [errorApelido, setErrorApelido] = useState(false);
  const [errorApelidoMessage, setErrorApelidoMessage] = useState("");

  const [errorSenha, setErrorSenha] = useState(false);
  const [errorSenhaMessage, setErrorSenhaMessage] = useState(
    "*Pelo menos 6 digitos"
  );

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorEmailMessage, setErrorEmailMessage] = useState("");

  const [errorTipo, setErrorTipo] = useState(false);
  const [errorTipoMessage, setErrorTipoMessage] = useState("");

  const [errorOrganizacao, setErrorOrganizacao] = useState(false);
  const [errorOrganizacaoMessage, setErrorOrganizacaoMessage] = useState("");

  const [districts, setDistricts] = useState([]);

  const handleChangeProvince = (event, value) => {
    setDistrito({});
    setProvincia(value);
    setDistricts(value?.districts);
  };

  const [checked, setChecked] = useState(false);
  const handleChangeChecked = (e) => setChecked(e.target.checked);

  const [showEliminar, setShowEliminar] = useState(false);
  const closeEliminar = () => {
    setShowEliminar(false);
    setChecked(false);
  };

  const [open, setOpen] = useState();
  const [message, setmessage] = useState();
  const [color, setColor] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (
      errorNome === false &&
      errorSenha === false &&
      errorEmail === false &&
      errorTipo === false
    ) {
      if (editing) {
        editar();
      } else {
        salvar();
      }
    } else {
      console.log("Não posso salvar");
    }
  };

  const requiredFields = () => {
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (nome === "" || nome === undefined) {
      setErrorNome(true);
      setErrorNomeMessage("Por favor, preencha este campo!");
    } else {
      setErrorNome(false);
      setErrorNomeMessage(true);
    }
    if (apelido === "" || apelido === undefined) {
      setErrorApelido(true);
      setErrorApelidoMessage("Por favor, preencha este campo!");
    } else {
      setErrorApelido(false);
      setErrorApelidoMessage(true);
    }
    if (senha === "" || senha === undefined || senha?.length < 6) {
      setErrorSenha(true);
      setErrorSenhaMessage("Senha inválida, pelo menos 6 dígitos!");
    } else {
      setErrorSenha(false);
      setErrorSenhaMessage(true);
    }
    if (email === "" || email === undefined || !email?.match(mailformat)) {
      setErrorEmail(true);
      setErrorEmailMessage("Endereço de email inválido, campo obrigatório!");
    } else {
      setErrorEmail(false);
      setErrorEmailMessage(true);
    }

    if (tipo === "" || tipo === undefined || Object.keys(tipo).length === 0) {
      setErrorTipo(true);
      setErrorTipoMessage("Por favor, seleccione uma opção");
    } else {
      setErrorTipo(false);
      setErrorTipoMessage(true);
    }
    if (
      organizacao === "" ||
      organizacao === undefined ||
      Object.keys(organizacao).length === 0
    ) {
      setErrorOrganizacao(true);
      setErrorOrganizacaoMessage("Por favor, seleccione uma opção");
    } else {
      setErrorOrganizacao(false);
      setErrorOrganizacaoMessage(true);
    }
  };

  const dados = {
    id: 1,
    nome,
    sobrenome: apelido,
    email,
    senha,
    data_nascimento: dataNascimento,
    numero_celular: contacto,
    genero: genero,
    endereco_residencial: endereco,
    provincia: provincia?.province,
    distrito,
    pais: "Moçambique",
    nivel_de_acesso: tipo,
    organizacao,
  };
  const cleanFields = () => {
    if (editing) setShowForm(false);
    setNome("");
    setApelido("");
    setEmail("");
    setSenha("");
    setDataNascimento(today);
    setContacto("");
    setGenero();
    setEndereco("");
    setProvincia();
    setDistrito();
    setTipo();
    setOrganizacao();
    setEditing(false);
    setUserId();
  };

  const startEditing = (user) => {
    setShowForm(true);
    setEditing(true);
    setUserId(user.id);
    setNome(user.nome);
    setApelido(user.sobrenome);
    setEmail(user.email);
    setSenha(user.senha);
    setDataNascimento(user.data_nascimento);
    setContacto(user.numero_celular);
    setGenero(user.genero);
    setEndereco(user.endereco_residencial);
    setProvincia(provincias.find((x) => x.province === user.provincia));
    setDistricts(
      provincias?.find((x) => x?.province === user?.provincia)?.districts
    );
    setDistrito(user.distrito);
    setTipo(user.nivel_de_acesso);
    setOrganizacao(user.organizacao);
  };

  const salvar = () => {
    setLoader(true);
    axios
      .post(`${BASE_URL}/utilizadores`, dados)
      .then((response) => {
        setOpen(true);
        setmessage("Utilizador registado com sucesso!");
        setColor("success");
        setLoader(false);
        cleanFields();
        fetchUsers();
      })
      .catch((error) => {
        setLoader(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const editar = () => {
    setLoader(true);
    axios
      .put(`${BASE_URL}/utilizadores/${userId}`, dados)
      .then((response) => {
        setOpen(true);
        setmessage("Dados actualizados com sucesso!");
        setColor("success");
        setLoader(false);
        cleanFields();
        fetchUsers();
        setShowForm(!showForm);
      })
      .catch((error) => {
        setLoader(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const eliminar = () => {
    setLoadAction(true);
    axios
      .delete(`${BASE_URL}/utilizadores/${selectedUser.id}`)
      .then((response) => {
        setOpen(true);
        setmessage("Utilizador emininado com sucesso!");
        setColor("success");
        setLoadAction(false);
        fetchUsers();
        closeEliminar();
      })
      .catch((error) => {
        setLoadAction(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const usersStatiscs = [
    {
      id: 1,
      type: "Super Administradores",
      value: () =>
        loadingTotal ? (
          <CircularProgress size={20} />
        ) : (
          totalUsers?.filter((x) => x.nivel_de_acesso === "Super administrador")
            .length
        ),
      filter: "Super administrador",
      icon: "material-symbols:admin-panel-settings-outline",
    },
    {
      id: 2,
      type: "Administradores",
      value: () =>
        loadingTotal ? (
          <CircularProgress size={20} />
        ) : (
          totalUsers?.filter((x) => x.nivel_de_acesso === "Administrador")
            .length
        ),
      filter: "Administrador",
      icon: "ri:admin-line",
    },
    {
      id: 3,
      type: "Responsáveis",
      value: () =>
        loadingTotal ? (
          <CircularProgress size={20} />
        ) : (
          totalUsers?.filter((x) => x.nivel_de_acesso === "Responsável").length
        ),
      filter: "Responsável",
      icon: "icon-park-outline:user-business",
    },
  ];

  return (
    <div className="container-fluid">
      <div className="text-center wow fadeInUp mb-2" data-wow-delay="0.1s">
        <h3 className="my-4 mb-5">Gestão de Utilizadores</h3>
      </div>
      <Row className="d-flex justify-content-center">
        {usersStatiscs.map((card) => (
          <Col sm={3}>
            <UsersCard
              object={card}
              filter={filter}
              setFilter={setFilter}
              setPage={() => setPage(0)}
            />
          </Col>
        ))}
      </Row>
      <Collapse in={showForm} timeout="auto" unmountOnExit disablePadding>
        <TableContainer
          component={"div"}
          className="bg-white shadow-sm border mb-4 py-4"
        >
          {/* -------------------FORMULÁRIO DE REGISTO------------- */}
          <div className="text-start text-secondary d-flex">
            <h4 className="px-3 text-secondary">Formulário de Registo</h4>
          </div>
          <div className="px-3">
            <form onSubmit={formSubmit}>
              <div className="row">
                <div className="col-md-3">
                  <CustomAutocomplete
                    id="clear-on-escape"
                    options={niveisAcesso}
                    getOptionLabel={(option) => option}
                    noOptionsText="Nenhuma opção encontrada"
                    onChange={(event, value) => setTipo(value)}
                    value={tipo}
                    disabled={loader}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        margin="normal"
                        size="small"
                        variant="standard"
                        label={
                          <span>
                            <Person
                              style={{ color: "#198754", marginTop: "-5" }}
                            />{" "}
                            Nível de acesso
                          </span>
                        }
                        className="rounded border ps-2"
                        type={"text"}
                        error={errorTipo}
                      />
                    )}
                  />
                  {errorTipo && (
                    <FormHelperText className="text-danger">
                      {errorTipoMessage}
                    </FormHelperText>
                  )}
                </div>
                <div className="col-md-3">
                  <CustomAutocomplete
                    id="clear-on-escape"
                    options={organizacoes}
                    getOptionLabel={(option) => option}
                    noOptionsText="Nenhuma opção encontrada"
                    onChange={(event, value) => setOrganizacao(value)}
                    value={organizacao}
                    disabled={loader}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        margin="normal"
                        size="small"
                        variant="standard"
                        label={
                          <span>
                            <Person
                              style={{ color: "#198754", marginTop: "-5" }}
                            />{" "}
                            Organização
                          </span>
                        }
                        className="rounded border ps-2"
                        type={"text"}
                        error={errorOrganizacao}
                      />
                    )}
                  />
                  {errorOrganizacao && (
                    <FormHelperText className="text-danger">
                      {errorOrganizacaoMessage}
                    </FormHelperText>
                  )}
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                  <div className="form-floating">
                    <TextField
                      variant="standard"
                      required
                      size="small"
                      margin="normal"
                      fullWidth
                      name="nome"
                      label={
                        <span>
                          <Person
                            style={{ color: "#198754", marginTop: "-5" }}
                          />{" "}
                          Nome
                        </span>
                      }
                      placeholder="Nome"
                      type={"text"}
                      id="nome"
                      className="rounded border"
                      value={nome}
                      defaultValue={nome}
                      error={errorNome}
                      onChange={(e) => setNome(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{ disableUnderline: true }}
                      disabled={loader}
                    />
                    {errorNome && (
                      <FormHelperText className="text-danger">
                        {errorNomeMessage}
                      </FormHelperText>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-floating">
                    <TextField
                      variant="standard"
                      required
                      size="small"
                      margin="normal"
                      fullWidth
                      name="apelido"
                      label={
                        <span>
                          <Person
                            style={{ color: "#198754", marginTop: "-5" }}
                          />{" "}
                          Apelido
                        </span>
                      }
                      placeholder="Apelido"
                      type={"text"}
                      id="apelido"
                      className="rounded border"
                      value={apelido}
                      defaultValue={apelido}
                      error={errorApelido}
                      onChange={(e) => setApelido(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{ disableUnderline: true }}
                      disabled={loader}
                    />
                    {errorApelido && (
                      <FormHelperText className="text-danger">
                        {errorApelidoMessage}
                      </FormHelperText>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <CustomAutocomplete
                    id="clear-on-escape"
                    options={generos}
                    getOptionLabel={(option) => option}
                    noOptionsText="Nenhuma opção encontrada"
                    onChange={(event, value) => setGenero(value)}
                    value={genero}
                    disabled={loader}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        size="small"
                        variant="standard"
                        label={
                          <span>
                            <Person
                              style={{ color: "#198754", marginTop: "-5" }}
                            />{" "}
                            Sexo
                          </span>
                        }
                        className="rounded border ps-2"
                        type={"text"}
                      />
                    )}
                  />
                </div>
                <div className="col-md-3">
                  <TextField
                    variant="standard"
                    size="small"
                    margin="normal"
                    fullWidth
                    name="dataNascimento"
                    label={
                      <span>
                        <Email style={{ color: "#198754", marginTop: "-5" }} />{" "}
                        Data de nascimento
                      </span>
                    }
                    placeholder="example@gmail.com"
                    className="rounded border"
                    type={"date"}
                    id="dataNascimento"
                    value={dataNascimento}
                    defaultValue={dataNascimento}
                    onChange={(e) => setDataNascimento(e.target.value)}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{
                      style: { border: "none", paddingLeft: 10 },
                    }}
                    disabled={loader}
                  />
                </div>
                <div className="col-md-3">
                  <div className="form-floating">
                    <CustomAutocomplete
                      id="clear-on-escape"
                      options={provincias}
                      getOptionLabel={(option) => option?.province}
                      noOptionsText="Nenhuma opção encontrada"
                      onChange={handleChangeProvince}
                      value={provincia}
                      disabled={loader}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          size="small"
                          variant="standard"
                          label={
                            <span>
                              <Person
                                style={{ color: "#198754", marginTop: "-5" }}
                              />{" "}
                              Província
                            </span>
                          }
                          className="rounded border ps-2"
                          type={"text"}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-floating">
                    <CustomAutocomplete
                      id="clear-on-escape"
                      options={districts || []}
                      getOptionLabel={(option) => option}
                      noOptionsText="Nenhuma opção encontrada"
                      onChange={(e, value) => setDistrito(value)}
                      value={distrito}
                      disabled={loader || !provincia}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          size="small"
                          variant="standard"
                          label={
                            <span>
                              <Person
                                style={{ color: "#198754", marginTop: "-5" }}
                              />{" "}
                              Distrito
                            </span>
                          }
                          className="rounded border ps-2"
                          type={"text"}
                        />
                      )}
                    />
                  </div>
                </div>{" "}
                <div className="col-md-3">
                  <div className="form-floating">
                    <TextField
                      variant="standard"
                      size="small"
                      margin="normal"
                      fullWidth
                      name="endereco"
                      label={
                        <span>
                          <Person
                            style={{ color: "#198754", marginTop: "-5" }}
                          />{" "}
                          Endereço
                        </span>
                      }
                      placeholder="Endereço residencial"
                      type={"text"}
                      id="endereco"
                      className="rounded border"
                      value={endereco}
                      defaultValue={endereco}
                      onChange={(e) => setEndereco(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{ disableUnderline: true }}
                      disabled={loader}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-floating">
                    <TextField
                      variant="standard"
                      size="small"
                      margin="normal"
                      fullWidth
                      name="contacto"
                      label={
                        <span>
                          <Person
                            style={{ color: "#198754", marginTop: "-5" }}
                          />{" "}
                          Telefone de contacto
                        </span>
                      }
                      placeholder="Número de telefone"
                      type={"text"}
                      id="contacto"
                      className="rounded border"
                      value={contacto}
                      defaultValue={contacto}
                      onChange={(e) => setContacto(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{ disableUnderline: true }}
                      disabled={loader}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="standard"
                    required
                    size="small"
                    margin="normal"
                    fullWidth
                    name="email"
                    label={
                      <span>
                        <Email style={{ color: "#198754", marginTop: "-5" }} />{" "}
                        Email
                      </span>
                    }
                    placeholder="example@gmail.com"
                    className="rounded border"
                    type={"text"}
                    id="email"
                    value={email}
                    defaultValue={email}
                    error={errorEmail}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{
                      style: { border: "none", paddingLeft: 10 },
                    }}
                    disabled={loader}
                  />
                  {errorEmail && (
                    <FormHelperText className="text-danger">
                      {errorEmailMessage}
                    </FormHelperText>
                  )}
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="standard"
                    required
                    size="small"
                    margin="normal"
                    fullWidth
                    name="senha"
                    className="rounded border"
                    label={
                      <span>
                        <Lock style={{ color: "#198754", marginTop: "-5" }} />{" "}
                        Senha
                      </span>
                    }
                    placeholder="******"
                    type={showPassword ? "text" : "password"}
                    id="senha"
                    value={senha}
                    defaultValue={senha}
                    error={errorSenha}
                    onChange={(e) => setSenha(e.target.value)}
                    autoComplete="new-password"
                    inputProps={{ style: { border: "none", paddingLeft: 10 } }}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            style={{ color: "#198754" }}
                            disabled={editing}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <Visibility fontSize="small" />
                            ) : (
                              <VisibilityOff fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    disabled={loader || editing}
                  />
                  {errorSenha ? (
                    <FormHelperText className="text-danger">
                      Campo obrigatório!
                    </FormHelperText>
                  ) : (
                    <FormHelperText>{errorSenhaMessage}</FormHelperText>
                  )}
                </div>
                <div className="col-12 d-flex justify-content-between align-items-center pb-2">
                  <Button
                    className="text-capitalize me-4 px-3 fw-bold"
                    style={{ color: "red" }}
                    onClick={() => cleanFields()}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    className="text-light text-capitalize"
                    style={{ backgroundColor: "#198754" }}
                    disabled={loader}
                    startIcon={
                      loader ? (
                        <Spinner
                          animation="border"
                          style={{ width: 24, height: 24 }}
                        />
                      ) : (
                        <Save />
                      )
                    }
                    onClick={() => requiredFields()}
                  >
                    {editing ? "Salvar alterações" : "Salvar"}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </TableContainer>
      </Collapse>
      <TableContainer component={"div"} className="bg-white shadow">
        {/* -------------------TABELA DE LISTAGEM------------- */}
        {loading && <LinearProgress className="w-100" />}

        {/* --------------------------- */}
        <div className="row w-100 pt-3 border-top">
          <div className="col-6 text-start p-3 text-secondary d-flex">
            <h4 className="px-3 text-secondary">Lista</h4>
          </div>

          <div className="col-6 d-flex justify-content-end my-auto p-0">
            <Button
              value="default"
              size="small"
              variant="contained"
              className="fw-bold text-capitalize"
              onClick={() => setShowForm(!showForm)}
              style={{
                color: showForm ? "#ffff" : "#198754",
                backgroundColor: showForm ? "#D5D5D5" : "#ffff",
                border: showForm ? "" : "1px solid #40A56E",
              }}
              startIcon={showForm ? <Block /> : <PersonAdd />}
            >
              {showForm ? "Ocultar formulário" : "Adicionar utilizador"}
            </Button>
          </div>
        </div>
        {/* -------------------------- */}
        <div className="row w-100 px-3">
          <div className="col-md-12 d-flex justify-content-start align-items-center">
            <TextField
              fullWidth
              variant="outlined"
              size={"small"}
              name="searchDescricao"
              disabled={!utilizadores?.length}
              label={<span style={{ fontSize: 13 }}>Nome...</span>}
              style={{ width: "23%" }}
              type={"text"}
              id="standard-start-adornment"
              value={nomeUser}
              onChange={(e) => setNomeUser(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ color: "#198754" }}
                      size="small"
                      className=""
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
                disableUnderline: true,
                style: {
                  borderRadius: 10,
                  paddingLeft: 1,
                },
              }}
            />

            {getParams() && (
              <Button
                className="text-capitalize ms-3"
                style={{ color: "#2075A9", backgroundColor: "#F5F5F5" }}
                startIcon={<ClearAll />}
                onClick={() => resetFilters()}
              >
                Limpar filtros
              </Button>
            )}
          </div>
        </div>

        <Table className="mt-3" aria-label="simple table">
          <TableHead className="table-head">
            <TableRow>
              <TableCell className="fw-bold">ID</TableCell>
              <TableCell className="fw-bold">Nome completo</TableCell>
              <TableCell className="fw-bold">Organização</TableCell>
              <TableCell className="fw-bold">Email</TableCell>
              <TableCell className="fw-bold">Telefone</TableCell>
              <TableCell className="fw-bold">Nível</TableCell>
              <TableCell className="fw-bold">Acções</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {utilizadores
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((d, index) => (
                <>
                  <TableRow key={d.id}>
                    <TableCell component="th" scope="row">
                      {d.id}
                    </TableCell>
                    <TableCell>
                      {d.nome} {d.sobrenome}
                    </TableCell>
                    <TableCell>{d.organizacao || "---"}</TableCell>
                    <TableCell>{d.email}</TableCell>
                    <TableCell>{d.numero_celular}</TableCell>
                    <TableCell>{d.nivel_de_acesso}</TableCell>
                    <TableCell className="ms-4">
                      <div className="dropdownrounded">
                        <IconButton
                          id="dropdownMenu2"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          size="small"
                          color="primary"
                        >
                          <MoreVert />
                        </IconButton>
                        <ul
                          className="dropdown-menu shadow detalhes-menu"
                          aria-labelledby="dropdownMenu2"
                        >
                          <li
                            className={`dropdown-item ${
                              auth?.role === "Super administrador" &&
                              "border-bottom"
                            }`}
                            onClick={() => startEditing(d)}
                          >
                            Editar
                          </li>
                          {auth?.role === "Super administrador" && (
                            <li
                              className="dropdown-item"
                              onClick={() => {
                                setShowEliminar(true);
                                setSelectedUser(d);
                              }}
                            >
                              Eliminar
                            </li>
                          )}
                        </ul>
                      </div>
                    </TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>

        {utilizadores?.length === 0 && !loading && (
          <div className="p-3 text-center d-inline-block w-100 align-items-center border-bottom justify-content-center">
            <div>
              <img
                src={nothing22}
                alt=""
                title="Nada para mostrar"
                style={{ width: "170px" }}
              />
            </div>
            <span className="text-secondary">
              Nenhum utilizador encontrado!
            </span>{" "}
          </div>
        )}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={utilizadores?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={"Linhas por página"}
        />
      </TableContainer>

      <Confirmacao
        show={showEliminar}
        closeModal={closeEliminar}
        action={eliminar}
        actionType={"Eliminar"}
        objectType={`o utilizador`}
        objectDescription={selectedUser?.nome}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom center"}
        autoHideDuration={5000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
    </div>
  );
}
