import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  ThemeProvider,
  createTheme,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import logo_kamba from "../../img/kamba/hug-world.png";
import { Edit } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import dateFormat from "dateformat";
import { getDeadLine } from "../utils/addDaysToDate";
import { Spinner } from "react-bootstrap";
import { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../requests/useGetData";
import SnackBar from "../snackbar";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FB7900", // Substitua pela cor desejada para o stepper
      color: "#FFFFFF",
    },
  },
});

const SuggestAppName = ({ show, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [sugestaoNome, setSugestaoNome] = useState();
  const [errorSugestao, setErrorSugestao] = useState(false);
  const [errorSugestaoMessage, setErrorSugestaoMessage] = useState("");
  const [showThanksMessage, setShowThanksMessage] = useState(false);

  const handleChangeSuggestion = (e) => {
    setSugestaoNome(e.target.value);
  };

  const handleCloseModal = () => {
    closeModal();
    setSugestaoNome();
    setShowThanksMessage(false);
  };

  const requiredFields = () => {
    if (sugestaoNome === "" || sugestaoNome === undefined) {
      setErrorSugestao(true);
      setErrorSugestaoMessage("Por favor, preencha este campo!");
    } else {
      setErrorSugestao(false);
      setErrorSugestaoMessage(true);
    }
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (errorSugestao === false) {
      salvar();
      localStorage.setItem("showSuggestion", JSON.stringify(true));
    } else {
      console.log("Não posso salvar");
    }
  };

  const [open, setOpen] = useState();
  const [message, setmessage] = useState();
  const [color, setColor] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const dados = {
    descricao: sugestaoNome,
  };

  const salvar = () => {
    setLoading(true);
    axios
      .post(`${BASE_URL}/nomes/store`, dados)
      .then((response) => {
        setLoading(false);
        setShowThanksMessage(true);
        /* setOpen(true);
        setmessage("Sugestão enviada com sucesso!");
        setColor("success"); */
        setSugestaoNome();
      })
      .catch((error) => {
        setLoading(false);
        setOpen(true);
        setmessage("Erro ao registar, por favor tente novamente!");
        setColor("error");
      });
  };

  return (
    <Dialog
      open={show}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      {/* <DialogTitle id="alert-dialog-title" className="fw-bold text-start">
        <strong>
          Olá, Bem Vindo ao APPCAI. <br /> Sente-se confortável com o nome deste
          aplicativo?
        </strong>
        <IconButton
          size="small"
          aria-label="close"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogTitle> */}
      <Divider />{" "}
      <form onSubmit={formSubmit}>
        <DialogContent className="pb-4">
          <div className="text-center">
            <img src={logo_kamba} alt="Holding hands" style={{ width: 100 }} />
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
              <h5 className="section-title  text-mains text-center text-white fw-bold main-app-logo ">
                APPCAI
              </h5>

              {showThanksMessage ? (
                <h5 className="mt-3">Obrigado!</h5>
              ) : (
                <h5 className="mb-5 mt-3">
                  Olá, Bem Vindo! <br /> <br /> Sente-se confortável com o nome
                  deste aplicativo?
                </h5>
              )}
            </div>
          </div>
          {showThanksMessage ? (
            <div className="my-2 border rounded p-3">
              Agradecemos imenso pela sua sugestão, será útil para melhoria
              deste aplicativo e, para que tenha uma boa experiência na sua
              utilização!
            </div>
          ) : (
            <>
              <div className="pt-1">
                Sugere um nome com o qual você se identifique:
              </div>
              <ThemeProvider theme={theme}>
                <div className="row">
                  <div className="col-12">
                    <div className="form-floating">
                      <TextField
                        variant="outlined"
                        size="small"
                        margin="normal"
                        fullWidth
                        name="sugestaoNome"
                        placeholder=""
                        type="text"
                        id="sugestaoNome"
                        value={sugestaoNome}
                        defaultValue={sugestaoNome}
                        onChange={handleChangeSuggestion}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Edit style={{ color: "#198754" }} />*
                            </InputAdornment>
                          ),
                          disableUnderline: true,
                        }}
                        inputProps={{
                          style: { border: "none", paddingLeft: 1 },
                        }}
                        disabled={loading}
                        error={errorSugestao}
                      />
                    </div>
                    {errorSugestao && (
                      <small style={{ color: "red" }}>
                        {errorSugestaoMessage}
                      </small>
                    )}
                  </div>
                </div>
              </ThemeProvider>
            </>
          )}
        </DialogContent>
        <Divider />
        <DialogActions className="px-2 px-md-4">
          {showThanksMessage ? (
            <Button
              className="text-capitalize mr-3"
              style={{ minWidth: 70, height: 33, color: "#2075A9" }}
              onClick={handleCloseModal}
              disabled={loading}
            >
              Fechar
            </Button>
          ) : (
            <>
              <Button
                className="text-capitalize mr-3"
                style={{ minWidth: 100, height: 33, color: "#2075A9" }}
                onClick={handleCloseModal}
                disabled={loading}
              >
                Não, obrigado!
              </Button>
              <Button
                type="submit"
                className="text-capitalize fw-bold"
                style={{
                  minWidth: 100,
                  height: 35,
                  color: "#ffff",
                  backgroundColor: loading ? "#" : "#FFA500",
                }}
                onClick={() => requiredFields()}
                disabled={loading}
              >
                {loading ? (
                  <Spinner
                    animation="border"
                    style={{ width: 20, height: 20 }}
                  />
                ) : (
                  "Enviar sugestão"
                )}
              </Button>
            </>
          )}
        </DialogActions>{" "}
      </form>
      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom center"}
        autoHideDuration={5000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
    </Dialog>
  );
};

export default SuggestAppName;
