import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  ThemeProvider,
  createTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Apps,
  ClearAll,
  FormatListBulleted,
  MoreVert,
} from "@material-ui/icons";
import { Collapse, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { BASE_URL } from "../requests/useGetData";
import axios from "axios";
import searchingFile from "../../img/kamba/searching1.gif";
import emergency from "../../img/kamba/emergency.png";
import { Autocomplete } from "@material-ui/lab";
import Confirmacao from "./confirmarAccao";
import SnackBar from "../snackbar";
import { provincias, tiposDenuncia } from "../utils/auxiliarData";
import dateFormat from "dateformat";
import IniciarDenuncia from "../modal/AssignComplaint";
import ComplaintsTableView from "./TableViewComplaints";
import emailjs from "@emailjs/browser";
import { Icon } from "@iconify/react";
import { useAuthUser } from "react-auth-kit";

const theme = createTheme({
  palette: {
    primary: {
      main: "#A6A6A6", // Substitua pela cor desejada para o stepper
    },
  },
});

export default function Complaints({ refetchUrgents }) {
  const auth = useAuthUser()()?.data
  const { estado } = useParams();
  const [loading, setLoading] = useState(true);
  const [loadAction, setLoadAction] = useState(false);
  const [selectedComplaint, setSelectedComplaint] = useState({});
  const [complaints, setComplaints] = useState([]);
  const [listView, setlistView] = useState("grid");
  const [filter, setFilter] = useState(false);

  const [districts, setDistricts] = useState([]);

  const [ref_bcc, setRef_bcc] = useState("");
  const [ref_concurso, setRef_concurso] = useState("");
  const [entidadeContratante, setEntidadeContratante] = useState({});
  const [entidadeContratada, setEntidadeContratada] = useState({});
  const [ano, setAno] = useState("");
  const [provincia, setProvincia] = useState("");
  const [distrito, setDistrito] = useState("");

  const handleChangeProvince = (event, value) => {
    setDistrito("");
    setProvincia(value);
    setDistricts(value?.districts);
  };
  const [open, setOpen] = useState();
  const [message, setmessage] = useState();
  const [color, setColor] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const [checked, setChecked] = useState(false);
  const handleChangeChecked = (e) => setChecked(e.target.checked);

  const [showAprovar, setShowAprovar] = useState(false);
  const closeAprovar = () => {
    setShowAprovar(false);
    setChecked(false);
  };

  const [showAddUrgency, setShowAddUrgency] = useState(false);
  const closeAddUrgency = () => {
    setShowAddUrgency(false);
    setChecked(false);
  };

  const [showRemoveUrgency, setShowRemoveUrgency] = useState(false);
  const closeRemoveUrgency = () => {
    setShowRemoveUrgency(false);
    setChecked(false);
  };

  const [showProcessar, setShowProcessar] = useState(false);
  const closeProcessar = () => {
    setShowProcessar(false);
    setChecked(false);
  };

  const [showFechar, setShowFechar] = useState(false);
  const closeFechar = () => {
    setShowFechar(false);
    setChecked(false);
  };

  const [showReabrir, setShowReabrir] = useState(false);
  const closeReabrir = () => {
    setShowReabrir(false);
    setChecked(false);
  };

  const [showEliminar, setShowEliminar] = useState(false);
  const closeEliminar = () => {
    setShowEliminar(false);
    setChecked(false);
  };

  const getParams = () => {
    var paramss = "";
    if (ref_bcc) {
      paramss += `&ref_bcc=${ref_bcc}`;
    }
    if (ref_concurso) {
      paramss += `&ref_concurso=${ref_concurso}`;
    }
    if (entidadeContratante?.id) {
      paramss += `&entidade_contratante_id=${entidadeContratante.id}`;
    }
    if (entidadeContratada?.id) {
      paramss += `&entidade_contratada_id=${entidadeContratada.id}`;
    }
    if (ano) {
      paramss += `&ano_anuncio=${ano}`;
    }
    if (provincia?.province) {
      paramss += `&provincia=${provincia?.province}`;
    }
    if (distrito) {
      paramss += `&distrito=${distrito}`;
    }

    return paramss;
  };

  const resetFilters = () => {
    setRef_bcc("");
    setRef_concurso("");
    setEntidadeContratante({});
    setEntidadeContratada({});
    setAno("");
    setProvincia("");
    setDistrito("");
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case "urgentes":
        return "urgente";
      case "pendentes":
        return "Pendente";
      case "aprovadas":
        return "Aprovada";
      case "em-processo":
        return "Em processo";
      case "fechadas":
        return "Fechada";
      default:
        return "";
    }
  };

  const getStatusTitle = (status) => {
    switch (status) {
      case "urgentes":
        return "Urgentes";
      case "pendentes":
        return "Pendentes";
      case "aprovadas":
        return "Aprovadas";
      case "em-processo":
        return "Em Processo";
      case "fechadas":
        return "Fechadas";
      default:
        return "";
    }
  };

  const fetchData = () => {
    setLoading(true);
    const filterStatusUser = {
      idUtilizador: auth?.id,
      status: getStatusLabel(estado),
    };

    if (estado === "urgentes") {
      axios
        .get(
          auth?.nivel_de_acesso === "Administrador" ||
            auth?.nivel_de_acesso === "Super administrador"
            ? `${BASE_URL}/denuncias`
            : `${BASE_URL}/denuncias/filter/byUser/${auth?.id}`
        )
        .then((response) => {
          setComplaints(response.data.data.filter((x) => x.urgente === "Sim"));
          setLoading(false);
        })
        .catch((error) => {
          setComplaints([]);
          setLoading(false);
          console.error("There was an error!", error);
        });
    } else if (estado === "pendentes" || estado === "aprovadas") {
      axios
        .get(`${BASE_URL}/denuncias/filter/byStatus/${getStatusLabel(estado)}`)
        .then((response) => {
          setComplaints(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          setComplaints([]);
          setLoading(false);
          console.error("There was an error!", error);
        });
    } else {
      axios
        .post(`${BASE_URL}/denuncias/filter/byUserAndEstado`, filterStatusUser)
        .then((response) => {
          setComplaints(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          setComplaints([]);
          setLoading(false);
          console.error("There was an error!", error);
        });
    }
  };
  useEffect(() => {
    fetchData();
  }, [estado]);

  const [utilizadores, setUtilizadores] = useState();

  const fetchUsers = () => {
    axios
      .get(`${BASE_URL}/utilizadores`)
      .then((response) => {
        setUtilizadores(response.data.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const aprovar = () => {
    setLoadAction(true);
    const dados = {
      status: "Aprovada",
    };
    axios
      .put(`${BASE_URL}/denuncias/${selectedComplaint.id}/status`, dados)
      .then(() => {
        endRequestActions("Denúncia aprovada com sucesso!", "success");
        fetchData();
        closeAprovar();
        closeReabrir();
      })
      .catch((error) => {
        endRequestActions(error?.response?.data?.message, "error");
      });
  };

  const addUrgency = () => {
    setLoadAction(true);
    const dados = {
      denuncia: {
        urgente: "Sim",
      },
    };
    axios
      .put(`${BASE_URL}/denuncias/${selectedComplaint.id}`, dados)
      .then(() => {
        endRequestActions("Denúncia marcada como URGENTE com sucesso!", "success");
        fetchData();
        closeAddUrgency();
        refetchUrgents();
      })
      .catch((error) => {
        endRequestActions(error?.response?.data?.message, "error");
      });
  };

  const removeUrgency = () => {
    setLoadAction(true);
    const dados = {
      denuncia: {
        urgente: "Não",
      },
    };
    axios
      .put(`${BASE_URL}/denuncias/${selectedComplaint.id}`, dados)
      .then((response) => {
        endRequestActions("Urgência retirada da denúncia com sucesso!", "success");
        fetchData();
        refetchUrgents();
        closeRemoveUrgency();
      })
      .catch((error) => {
        endRequestActions(error?.response?.data?.message, "error");
      });
  };

  const endRequestActions = (statusMessage, statusColor) => {
    setOpen(true);
    setmessage(statusMessage);
    setColor(statusColor);
    setLoadAction(false);
  };
  (function () {
    emailjs.init("uGmOHu_FFDIUEuHqE");
  })();

  function sendmail(assignee, prazo) {
    var emailParams = {
      to_name: assignee?.nome,
      urgente: selectedComplaint?.urgente === "Sim" ? "urgente" : "",
      tipo_denuncia: selectedComplaint.tipo_denuncia,
      provincia: selectedComplaint.provincia,
      distrito: selectedComplaint.distrito,
      id_denuncia: selectedComplaint.id,
      to_email: assignee?.email,
      prazo: dateFormat(prazo, "dd.mm.yyyy"),
    };
    emailjs
      .send("service_iljpxzj", "template_7rspfdd", emailParams)
      .then(function (res) {
        endRequestActions("Processo da denúncia iniciado com sucesso!", "success");
        fetchData();
        closeProcessar();
        console.log("email response", res)
      })
      .catch((error) => {
        endRequestActions("Ocorreu um erro ao notificar responsável", "error");
      });
  }

  const processar = (assignee, dataInicio, prazo) => {
    setLoadAction(true);
    const dados = {
      denuncia: {
        idUtilizador: assignee?.id,
        status: "Em processo",
        data_inicio: dataInicio,
        data_fim: prazo,
      },
    };
    //sendmail(assignee, prazo);

    axios
      .put(`${BASE_URL}/denuncias/${selectedComplaint.id}`, dados)
      .then((response) => {
        sendmail(assignee, prazo);
        endRequestActions("Processo da denúncia iniciado com sucesso!", "success");
        fetchData();
        closeProcessar();
      })
      .catch((error) => {
        setLoadAction(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };
  const fechar = () => {
    setLoadAction(true);
    const dados = {
      status: "Fechada",
    };
    axios
      .put(`${BASE_URL}/denuncias/${selectedComplaint.id}/status`, dados)
      .then((response) => {
        endRequestActions("Denúncia encerrada com sucesso!", "success");
        fetchData();
        closeFechar();
      })
      .catch((error) => {
        endRequestActions(error?.response?.data?.message, "error");
      });
  };

  const eliminar = () => {
    setLoadAction(true);
    axios
      .delete(`${BASE_URL}/denuncias/${selectedComplaint.id}`)
      .then((response) => {
        endRequestActions("Denúncia eliminada com sucesso!", "success");
        fetchData();
        closeEliminar();
      })
      .catch((error) => {
        endRequestActions(error?.response?.data?.message, "error");
      });
  };
  return (
    <div>
      <div className="mt-3 border-bottom">
        <div className="d-flex justify-content-between">
          <h3>
            Lista de Denúncias{" "}
            <span style={{ color: estado === "urgentes" && "red" }}>
              {getStatusTitle(estado)}{" "}
            </span>{" "}
          </h3>
          <div className="d-flex">
            {" "}
            <small
              onClick={() => setlistView("grid")}
              className={`${
                listView === "grid" && "active-switch-view-label"
              } p-1 px-2 border-end swicth-view-label`}
            >
              <Apps style={{ fontSize: 12, marginRight: 7 }} /> Vista de grelha
            </small>
            <small
              onClick={() => setlistView("list")}
              className={`${
                listView === "list" && "active-switch-view-label"
              } p-1 px-2 swicth-view-label`}
            >
              <FormatListBulleted style={{ fontSize: 12, marginRight: 7 }} />{" "}
              Vista de lista
            </small>
            <IconButton
              size="small"
              variant="contained"
              onClick={() => setFilter(!filter)}
              style={{
                color: filter ? "#fff" : "#6E6E6E",
                backgroundColor: filter ? "#FB7900" : "#FFFF",
                width: 35,
                height: 35,
              }}
              className="mx-1 icon-button-searc"
            >
              <Search />
            </IconButton>
          </div>
        </div>
      </div>
      {/* -----------FILTER-------------------- */}
      <Collapse in={filter}>
        <div className="bg-white px-3 pb-1 shadow-sm border border-top-0">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex my-2" style={{ color: "#FB7900" }}>
              Digite os dados para pesquisar...
            </div>
            <div>
              {getParams() && (
                <Button
                  className="text-capitalize mt-2"
                  style={{ color: "#FB7900" }}
                  startIcon={<ClearAll />}
                  onClick={() => resetFilters()}
                >
                  Limpar filtros
                </Button>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <ThemeProvider theme={theme}>
                <TextField
                  className="mr-2"
                  fullWidth
                  variant="standard"
                  size="small"
                  name="searchDescricao"
                  label="Nome da vítima"
                  style={{ marginTop: 17 }}
                  value={ref_bcc}
                  onChange={(e) => setRef_bcc(e.target.value)}
                  type={"text"}
                  id="standard-start-adornment"
                />
              </ThemeProvider>
            </div>
            <div className="col">
              <ThemeProvider theme={theme}>
                <TextField
                  className="mr-2"
                  fullWidth
                  variant="standard"
                  size="small"
                  name="searchDescricao"
                  label="Nome do agressor"
                  style={{ marginTop: 17 }}
                  value={ref_concurso}
                  onChange={(e) => setRef_concurso(e.target.value)}
                  type={"text"}
                  id="standard-start-adornment"
                />
              </ThemeProvider>
            </div>
            <div className="col">
              <Autocomplete
                className="mr-2"
                id="clear-on-escape"
                options={tiposDenuncia}
                getOptionLabel={(option) => option}
                noOptionsText="Nenhuma opção encontrada"
                style={{ width: "100%" }}
                onChange={(e, value) => setAno(value)}
                value={ano}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    size="small"
                    variant="standard"
                    label="Tipo de denúncia"
                    className="rounded"
                    type={"text"}
                  />
                )}
              />
            </div>
            <div className="col">
              <Autocomplete
                className="mr-2"
                id="clear-on-escape"
                options={provincias}
                getOptionLabel={(option) => option?.province}
                noOptionsText="Nenhuma opção encontrada"
                style={{ width: "100%" }}
                onChange={handleChangeProvince}
                value={provincia}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    size="small"
                    variant="standard"
                    label="Província"
                    className="rounded"
                    type={"text"}
                  />
                )}
              />
            </div>
            <div className="col">
              <Autocomplete
                className="mr-2"
                id="clear-on-escape"
                options={districts || []}
                getOptionLabel={(option) => option}
                noOptionsText="Nenhuma opção encontrada"
                style={{ width: "100%" }}
                onChange={(event, value) => setDistrito(value)}
                value={distrito}
                disabled={!provincia}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    size="small"
                    variant="standard"
                    label="Distrito"
                    className="rounded"
                    type={"text"}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </Collapse>

      <div>
        {complaints?.length === 0 && !loading && (
          <div className="p-5 text-center d-inline-block w-100 align-items-center justify-content-center">
            <div>
              <img
                src={searchingFile}
                alt=""
                title="Nada para mostrar"
                style={{ width: "170px" }}
              />
            </div>
            <span>Nenhum resultado encontrado!</span>{" "}
          </div>
        )}
        {loading ? (
          <div className="text-center my-5 py-5">
            <div class="my-4 py-4">
              <div class="my-5 py-5">
                <div class="loader"></div>
              </div>
            </div>
          </div>
        ) : listView === "grid" ? (
          <div className="row g-4 mt-3">
            {complaints?.map((complaint, i) => (
              <div className="col-12 col-sm-6 col-md-6 col-lg-3" key={i}>
                <div className="shadow-sm border-bottom rounded main-cards">
                  <span className="">
                    <div
                      className={`row g-1 text-start mb-0 card-header rounded ${
                        complaint?.urgente === "Sim" && "red-card-header"
                      }`}
                    >
                      <CardHeader
                        className=""
                        subheader={
                          <small>
                            {estado === "urgentes"
                              ? complaint?.status
                              : complaint?.provincia || "---"}
                          </small>
                        }
                        action={
                          <div className="mt-2">
                            <IconButton
                              aria-label="settings"
                              id="dropdownMenu2"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              size="small"
                            >
                              <MoreVert />
                            </IconButton>

                            <ul
                              className="dropdown-menu shadow rounded"
                              aria-labelledby="dropdownMenu2"
                            >
                              {complaint?.urgente === "Não" && (
                                <li
                                  onClick={() => {
                                    setShowAddUrgency(true);
                                    setSelectedComplaint(complaint);
                                  }}
                                  className="dropdown-item"
                                >
                                  Marcar como urgente
                                </li>
                              )}
                              {complaint?.urgente === "Sim" && (
                                <li
                                  onClick={() => {
                                    setShowRemoveUrgency(true);
                                    setSelectedComplaint(complaint);
                                  }}
                                  className="dropdown-item"
                                >
                                  Retirar urgência
                                </li>
                              )}
                              {complaint?.status === "Pendente" && (
                                <li
                                  onClick={() => {
                                    setShowAprovar(true);
                                    setSelectedComplaint(complaint);
                                  }}
                                  className="dropdown-item"
                                >
                                  Aprovar
                                </li>
                              )}
                              {complaint?.status === "Aprovada" && (
                                <li
                                  onClick={() => {
                                    setShowProcessar(true);
                                    setSelectedComplaint(complaint);
                                  }}
                                  className="dropdown-item"
                                >
                                  Iniciar processo
                                </li>
                              )}
                              {(complaint?.status === "Aprovada" ||
                                complaint?.status === "Em processo") && (
                                <li
                                  onClick={() => {
                                    setShowFechar(true);
                                    setSelectedComplaint(complaint);
                                  }}
                                  className="dropdown-item"
                                >
                                  Fechar
                                </li>
                              )}{" "}
                              {complaint?.status === "Fechada" && (
                                <li
                                  onClick={() => {
                                    setShowReabrir(true);
                                    setSelectedComplaint(complaint);
                                  }}
                                  className="dropdown-item"
                                >
                                  Reabrir
                                </li>
                              )}
                              <Link to={`/editarDenuncia/${complaint.id}`}>
                                <li className="dropdown-item">Editar</li>
                              </Link>
                              <li
                                onClick={() => {
                                  setShowEliminar(true);
                                  setSelectedComplaint(complaint);
                                }}
                                className="dropdown-item"
                              >
                                Eliminar
                              </li>
                            </ul>
                          </div>
                        }
                        style={{ height: 30, padding: 0 }}
                      />

                      <div className="col-12 mt-3 d-flex align-items-center justify-content-start">
                        <div className="w-100">
                          <p
                            className="fw-bold mb-0"
                            style={{
                              color: "#FB7900",
                            }}
                          >
                            {complaint?.vitima?.nome ||
                              complaint?.testemunha?.nome ||
                              "---"}
                          </p>
                          <div className="d-flex justify-content-between align-items-center">
                            <small className="pt-1">
                              {complaint?.tipo_denuncia || "---"}
                            </small>
                            <img
                              hidden={complaint?.urgente !== "Sim"}
                              src={emergency}
                              alt="Urgente"
                              title="Caso urgente!"
                              style={{ width: 25 }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between pb-1 pt-2 px-3">
                      <small>Código:</small>
                      <strong>
                        <small>{complaint?.ref || "---"}</small>{" "}
                      </strong>
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                      <div className="divider3"></div>
                    </div>

                    <CardContent className="text-start mt-0 pt-2 d-longa">
                      <Icon
                        icon="mingcute:quote-left-fill"
                        width="20"
                        height="20"
                        className="text-primary"
                      />
                      <p className="history-desc text-secondary ms-2">
                        {complaint?.descricao}
                      </p>
                    </CardContent>
                  </span>

                  <CardActions className="">
                    <div className="d-flex justify-content-between w-100">
                      <Link to={`/editarDenuncia/${complaint.id}`}>
                        <small className="more-card">Mais detalhes</small>
                      </Link>
                      <small className="date-card">
                        {dateFormat(complaint?.created_at, "dd/mm/yyyy, HH:MM")}
                      </small>
                    </div>
                  </CardActions>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <ComplaintsTableView
            complaints={complaints}
            setSelectedComplaint={setSelectedComplaint}
            setShowAddUrgency={setShowAddUrgency}
            setShowRemoveUrgency={setShowRemoveUrgency}
            setShowAprovar={setShowAprovar}
            setShowProcessar={setShowProcessar}
            setShowReabrir={setShowReabrir}
            setShowEliminar={setShowEliminar}
            setShowFechar={setShowFechar}
          />
        )}
      </div>

      <Confirmacao
        show={showAddUrgency}
        closeModal={closeAddUrgency}
        action={addUrgency}
        actionType={"Marcar como URGENTE"}
        objectType={`a denúncia ${selectedComplaint?.vitima?.nome ? "de" : ""}`}
        objectDescription={selectedComplaint?.vitima?.nome || ""}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      <Confirmacao
        show={showRemoveUrgency}
        closeModal={closeRemoveUrgency}
        action={removeUrgency}
        actionType={"Remover a urgência"}
        objectType={`da denúncia ${
          selectedComplaint?.vitima?.nome ? "de" : ""
        }`}
        objectDescription={selectedComplaint?.vitima?.nome || ""}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      <Confirmacao
        show={showAprovar}
        closeModal={closeAprovar}
        action={aprovar}
        actionType={"Aprovar"}
        objectType={`a denúncia ${selectedComplaint?.vitima?.nome ? "de" : ""}`}
        objectDescription={selectedComplaint?.vitima?.nome || ""}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      {/* <Confirmacao
        show={showProcessar}
        closeModal={closeProcessar}
        action={processar}
        actionType={"Iniciar processo"}
        objectType={`da denúncia ${
          selectedComplaint?.vitima?.nome ? "de" : ""
        }`}
        objectDescription={selectedComplaint?.vitima?.nome || ""}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      /> */}

      <IniciarDenuncia
        show={showProcessar}
        closeModal={closeProcessar}
        denuncia={selectedComplaint}
        action={processar}
        users={utilizadores}
        loader={loadAction}
      />

      <Confirmacao
        show={showFechar}
        closeModal={closeFechar}
        action={fechar}
        actionType={"Fechar"}
        objectType={`a denúncia ${selectedComplaint?.vitima?.nome ? "de" : ""}`}
        objectDescription={selectedComplaint?.vitima?.nome || ""}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      <Confirmacao
        show={showReabrir}
        closeModal={closeReabrir}
        action={aprovar}
        actionType={"Reabrir"}
        objectType={`a denúncia ${selectedComplaint?.vitima?.nome ? "de" : ""}`}
        objectDescription={selectedComplaint?.vitima?.nome || ""}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      <Confirmacao
        show={showEliminar}
        closeModal={closeEliminar}
        action={eliminar}
        actionType={"Eliminar"}
        objectType={`a denúncia ${selectedComplaint?.vitima?.nome ? "de" : ""}`}
        objectDescription={selectedComplaint?.vitima?.nome || ""}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom center"}
        autoHideDuration={5000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
    </div>
  );
}
