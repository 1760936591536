/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "../../../css/chart.css";
import ChartComponent from "../../charts/basicCharts";
import { estados, tiposDenuncia } from "../../utils/auxiliarData";

const TipoEstado = ({ title, dashboardData }) => {
  var urgents;
  var pendings;
  var in_processs;
  var closed;

  if (dashboardData) {
    const keys = Object.keys(dashboardData); // Obtém todas as chaves do objeto
    urgents = keys?.map((key) => dashboardData?.[key]?.urgent) ?? [];
    pendings = keys?.map((key) => dashboardData?.[key]?.pending) ?? [];
    in_processs = keys?.map((key) => dashboardData?.[key]?.in_process) ?? [];
    closed = keys?.map((key) => dashboardData?.[key]?.closed) ?? [];
  }

  var data = {
    labels: tiposDenuncia,
    datasets: [
      {
        label: estados[0],
        /* backgroundColor: "rgba(244, 67, 54, 1)", */
        backgroundColor: "rgba(220, 53, 69, 0.9)",
        borderColor: "rgba(255, 255, 255, 1)",
        borderRadius: 2,
        borderWidth: 1,
        barThickness: 25,
        data: urgents,
      },

      {
        label: estados[1],
        /* backgroundColor: "rgba(255, 152, 0, 1)", */
        backgroundColor: "rgba(255, 151, 83, 1)",
        borderColor: "rgba(255, 255, 255, 1)",
        borderRadius: 2,
        borderWidth: 1,
        barThickness: 25,
        data: pendings,
      },

      {
        label: estados[2],
        /* backgroundColor: "rgba(45, 109, 155, 1)", */
        backgroundColor: "rgba(25, 135, 84, 1)",
        borderColor: "rgba(255, 255, 255, 1)",
        borderRadius: 2,
        borderWidth: 1,
        barThickness: 25,
        data: in_processs,
      },
      {
        label: estados[3],
        /* backgroundColor: "rgba(94, 209, 209, 1)", */
        backgroundColor: "rgba(74, 153, 229, 1)",
        borderColor: "rgba(255, 255, 255, 1)",
        borderRadius: 2,
        borderWidth: 1,
        barThickness: 25,
        data: closed,
      },
    ],
  };

  return (
    <ChartComponent
      title={title}
      //loader={isFetching}
      data={data}
      chartType="bar"
      indexAxis="x"
      legendPosition="top"
      showLegend={true}
    />
  );
};

export default TipoEstado;
