import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { CallOutlined, CheckCircle, Close } from "@material-ui/icons";
import linha from "../img/kamba/linha.png";
import whatsap from "../img/kamba/whatsApp.png";
import messages from "../img/kamba/messages.png";
import call from "../img/kamba/call.jpg";

const useStyles = makeStyles((theme) => ({
  imageList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0.2) 70%, rgba(255,255,255,0) 100%)",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const InfoModal = ({ show, closeModal, code }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={show}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      disableBackdropClick
    >
      <DialogTitle
        id="alert-dialog-title"
        className="fw-bold text-start shad border-bottom"
      >
        <div className="d-flex align-items-center">
          <CheckCircle fontSize="large" className="text-success me-2" />
          <h4 className="mb-0">Dados recolhidos com sucesso!</h4>
        </div>

        <IconButton
          size="small"
          aria-label="close"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent className="mt-2 border-bottom">
        <div>
          <div>
            Agradecemos pela sua colaboração, a sua história foi registada com
            sucesso.
          </div>{" "}
          <div className="mt-3">
            A sua história é reprensentada pelo código{" "}
            <strong className="text-danger">{code}</strong>, por favor guarde-o
            em um local seguro.
          </div>{" "}
          <div className="mt-3">
            Se precisar, não hesite e entre em contacto pelas nossas linhas de
            apoio:
          </div>
        </div>
        <div className="row w-100 d-flex align-items-center my-2">
          <div className="col-md-5 text-center">
            <img src={linha} alt="Linha de apoio" style={{ maxWidth: 170 }} />
          </div>
          <div className="col-md-7">
            <div className="div-criative">
              <span className="text-">
                - Linha Fala Criança:{" "}
                <strong style={{ color: "black" }}>116</strong>;
              </span>{" "}
              <div>
                <img
                  src={call}
                  alt="Linha de apoio"
                  className="ms-2"
                  style={{ maxWidth: 25 }}
                />
                <img
                  src={messages}
                  alt="Linha de apoio"
                  className="ms-2"
                  style={{ maxWidth: 25 }}
                />
                <img
                  src={whatsap}
                  alt="Linha de apoio"
                  className="ms-2"
                  style={{ maxWidth: 25 }}
                />
              </div>
              <span>- Ligue, envie SMS ou WhatsApp para:</span> <br />{" "}
              <strong>+258 877 999 666</strong> <br />
              <small>
                <strong style={{ color: "red" }}>
                  É de borla, é confidencial!
                </strong>
              </small>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center">
        <Button onClick={closeModal} className="text-danger text-capitalize">
          Sair
        </Button>
        <a href="tel:+258877999666" className="btn_1">
          <CallOutlined /> Ligar agora
        </a>
      </DialogActions>
    </Dialog>
  );
};

export default InfoModal;
