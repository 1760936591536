/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AppbarDrawer from "../../../components/drawer/drawer";
import Complaints from "../../../components/complaintesAdmin/complaints";
import axios from "axios";
import { BASE_URL } from "../../../components/requests/useGetData";
import { useAuthUser } from "react-auth-kit";

const ComplaintsPage = () => {
  const auth = useAuthUser()()?.data
  const [complaints, setComplaints] = useState([]);

  const fetchData = () => {
    axios
      .get(
        auth?.nivel_de_acesso === "Administrador" ||
          auth?.nivel_de_acesso === "Super administrador"
          ? `${BASE_URL}/denuncias`
          : `${BASE_URL}/denuncias/filter/byUser/${auth?.id}`
      )
      .then((response) => {
        setComplaints(response?.data.data.filter((x) => x.urgente === "Sim"));
      })
      .catch((error) => {
        setComplaints([]);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <AppbarDrawer complaints={complaints}>
        <Complaints refetchUrgents={fetchData} />
      </AppbarDrawer>
    </div>
  );
};
export default ComplaintsPage;
