import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  ThemeProvider,
  createTheme,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { Close, Event, Person } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import dateFormat from "dateformat";
import { getDeadLine } from "../utils/addDaysToDate";
import { Spinner } from "react-bootstrap";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#FB7900", // Substitua pela cor desejada para o stepper
      color: "#FFFFFF",
    },
  },
});

const CustomAutocomplete = withStyles((theme) => ({
  root: {
    "& .MuiInput-underline:before": {
      borderBottomColor: "#198754",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "#198754",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#198754",
    },
    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "#E1E4E8",
    },
  },
}))(Autocomplete);

const today = dateFormat(new Date(), "yyyy-mm-dd");
const IniciarDenuncia = ({
  show,
  closeModal,
  denuncia,
  users,
  action,
  loader,
}) => {
  const classes = useStyles();
  const [dataInicio, setDataInicio] = useState(today);
  const deadlineDays = denuncia?.urgente === "Sim" ? 2 : 3;
  const [prazo, setPrazo] = useState(getDeadLine(dataInicio, deadlineDays));
  const [responsavel, setResponsavel] = useState();
  const [errorResponsavel, setErrorResponsavel] = useState(false);
  const [errorResponsavelMessage, setErrorResponsavelMessage] = useState("");

  const handleChangenitialDate = (e) => {
    setDataInicio(e.target.value);
    setPrazo(getDeadLine(e.target.value, deadlineDays));
  };

  useEffect(() => {
    setPrazo(getDeadLine(dataInicio, deadlineDays));
  }, [dataInicio, deadlineDays]);

  const handleCloseModal = () => {
    closeModal();
    setResponsavel();
    setDataInicio(today);
  };

  const requiredFields = () => {
    if (responsavel === "" || responsavel === undefined) {
      setErrorResponsavel(true);
      setErrorResponsavelMessage("Por favor, preencha este campo!");
    } else {
      setErrorResponsavel(false);
      setErrorResponsavelMessage(true);
    }
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (errorResponsavel === false) {
      action(responsavel, dataInicio, prazo);
    } else {
      console.log("Não posso salvar");
    }
  };

  return (
    <Dialog
      open={show}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title" className="fw-bold text-start">
        Aprovação de denúncia <span style={{color: "red"}}>{denuncia?.urgente === "Sim" ? "URGENTE" : ""}</span> 
        <IconButton
          size="small"
          aria-label="close"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogTitle>
      <Divider />{" "}
      <form onSubmit={formSubmit}>
        <DialogContent className="pb-4">
          {" "}
          <div className="pt-1">
            {" "}
            Seleccione um responsável para que dê seguimento a esta denúncia de{" "}
            <strong>
              {denuncia?.vitima?.nome || denuncia?.testemunha?.nome || "---"}
            </strong>
            :
          </div>
          <ThemeProvider theme={theme}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-floating">
                  <CustomAutocomplete
                    id="clear-on-escape"
                    options={users}
                    getOptionLabel={(option) =>
                      `${option?.nome} ${option?.sobrenome}`
                    }
                    noOptionsText="Nenhuma opção encontrada"
                    onChange={(e, value) => setResponsavel(value)}
                    value={responsavel}
                    //disabled={loader}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        size="small"
                        variant="standard"
                        label={
                          <span>
                            <Person
                              style={{ color: "#198754", marginTop: "-5" }}
                            />{" "}
                            Atribuir responsável
                          </span>
                        }
                        className="rounded"
                        error={errorResponsavel}
                        helperText={errorResponsavelMessage}
                        type={"text"}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-6 mt-4">
                <TextField
                  variant="standard"
                  required
                  size="small"
                  margin="normal"
                  fullWidth
                  name="dataInicio"
                  label={
                    <span>
                      <Event style={{ color: "#198754", marginTop: "-5" }} />{" "}
                      Data de Inicio
                    </span>
                  }
                  placeholder="example@gmail.com"
                  className="rounded border-bottom"
                  type={"date"}
                  id="dataInicio"
                  value={dataInicio}
                  defaultValue={dataInicio}
                  onChange={handleChangenitialDate}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{
                    style: { border: "none", paddingLeft: 10 },
                  }}
                  //disabled={loader}
                />
              </div>
              <div className="col-md-6 mt-4">
                <TextField
                  variant="standard"
                  required
                  size="small"
                  margin="normal"
                  fullWidth
                  name="prazo"
                  label={
                    <span>
                      <Event style={{ color: "#198754", marginTop: "-5" }} />{" "}
                      Data de término do caso (
                      {denuncia?.urgente === "Sim" ? 2 : 3} dias)
                    </span>
                  }
                  placeholder="example@gmail.com"
                  className="rounded border-bottom"
                  type={"date"}
                  disabled
                  id="prazo"
                  value={prazo}
                  defaultValue={prazo}
                  onChange={(e) => setPrazo(e.target.value)}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{
                    style: { border: "none", paddingLeft: 10 },
                  }}
                  //disabled={loader}
                />
              </div>
            </div>
          </ThemeProvider>
        </DialogContent>
        <Divider />
        <DialogActions className="px-4">
          <Button
            className="text-capitalize mr-3"
            style={{ width: 100, height: 33, color: "red" }}
            onClick={handleCloseModal}
            disabled={loader}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            className="text-capitalize fw-bold"
            style={{
              width: 100,
              height: 33,
              color: "#2075A9",
              backgroundColor: loader && "#",
            }}
            onClick={() => requiredFields()}
            disabled={loader}
          >
            {loader ? (
              <Spinner animation="border" style={{ width: 20, height: 20 }} />
            ) : (
              "Confirmar"
            )}
          </Button>
        </DialogActions>{" "}
      </form>
    </Dialog>
  );
};

export default IniciarDenuncia;
