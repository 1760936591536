function getRemainingDays(dueDate) {
    const currentDate = new Date();
    const convertedDueDate = new Date(dueDate);
  
    currentDate.setHours(0, 0, 0, 0);
    convertedDueDate.setHours(0, 0, 0, 0);
  
    const remainingTime = convertedDueDate.getTime() - currentDate.getTime();
    const remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
  
    if (remainingDays > 0) {
        return {
            value:`${remainingDays} dia${remainingDays > 1 ? "s" : ""} remanescente${remainingDays > 1 ? "s" : ""}`,
            color: "green"
        }
      } else if (remainingDays < 0) {
        return {
            value:`${Math.abs(remainingDays)} dia${Math.abs(remainingDays) > 1 ? "s" : ""} atrasado${Math.abs(remainingDays) > 1 ? "s" : ""}`,
            color: "red"
        }
      } else {
        return {
            value: "Hoje é o prazo!",
            color: "blue"
        }
      }
  }

  export { getRemainingDays }