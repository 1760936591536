/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { Chart, registerables } from "chart.js";
import "../../css/chart.css";

Chart.register(...registerables);
/* Este componente permite a visualização de gráficos do tipo PIE e MULTIPLE BAR */
const ChartComponent = ({
  chartType,
  title,
  indexAxis,
  data,
  legendPosition,
  showLegend,
}) => {
  const canvasRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  const chartConfig = {
    type: chartType,
    data: data,
    options: {
      indexAxis: indexAxis,
      barValueSpacing: 20,
      elements: {
        bar: {
          borderWidth: 3,
        },
      },
      scales: {},
      responsive: true,
      plugins: {
        legend: {
          position: legendPosition,
          display: showLegend ?? true,
        },
        title: {
          display: true,
          text: title,
          font: {
            size: 14,
          },
        },
      },
    },
  };

  useEffect(() => {
    const blogUsersOverview = new Chart(canvasRef.current, chartConfig);
    blogUsersOverview.render();

    return () => {
      blogUsersOverview.destroy();
    };
  }, [chartConfig, chartInstance]);

  return (
    <div className="">
      <canvas
        height="100"
        ref={canvasRef}
        style={{ maxWidth: "100% !important" }}
      />
    </div>
  );
};

export default ChartComponent;
