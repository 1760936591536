import { Menu } from "@material-ui/icons";
import React from "react";
import { NavLink } from "react-router-dom";
import mgcas from "../img/kamba/mgcas.png";

export default function Appbar() {
  return (
    <div className="">
      <nav className="navbar navbar-expand-lg navbar-dark shadow-sm px-4 ps-lg-5 pe-lg-5 py-3 py-lg-0 display-flex justify-content-between">
        <NavLink to={"/home"} className="mt-3 mb-2 ms-lg-5 ps-lg-4">
          <h2 className="main-app-logo">APPCAI</h2>
        </NavLink>

        <button
          className="navbar-toggler btn-primary"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <Menu />
        </button>

        <div className="ms-auto py-2 w-100 text-primary">
          <img src={mgcas} alt="Unicef" width={50} /> <br />
          <div>
            <p className="mb-0">República de Moçambique</p>
            <p className="mb-0">Ministério do Gênero, Criança e Acção Social</p>
          </div>
        </div>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0 pe-4">
            {" "}
            <NavLink to={"/home"} className="nav-item nav-link nav-link1">
              Início
            </NavLink>
            <NavLink to={"/contar-nos"} className="nav-item nav-link nav-link1">
              Contar
            </NavLink>
          </div>
        </div>
      </nav>
    </div>
  );
}
