import React from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import emergency from "../../img/kamba/emergency.png";
import { MoreVert } from "@material-ui/icons";
import dateFormat from "dateformat";
import { Link, useParams } from "react-router-dom";

export default function ComplaintsTableView({
  complaints,
  setSelectedComplaint,
  setShowAddUrgency,
  setShowRemoveUrgency,
  setShowAprovar,
  setShowProcessar,
  setShowReabrir,
  setShowEliminar,
  setShowFechar,
}) {
  const { estado } = useParams();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const formattDeadLine = (prazo) => {
    const currentDate = new Date();
    const prazoDate = prazo ? new Date(prazo) : null;
    if (prazoDate && prazoDate < currentDate) {
      return true;
    }
    return false;
  };

  return (
    <TableContainer component={"div"} className="bg-white shadow my-5">
      {/* -------------------TABELA DE LISTAGEM------------- */}

      <Table aria-label="simple table">
        <TableHead className="table-head">
          <TableRow>
            <TableCell className="fw-bold">Código</TableCell>
            <TableCell className="fw-bold">Nome completo</TableCell>
            <TableCell className="fw-bold">Telefone</TableCell>
            <TableCell className="fw-bold">Província</TableCell>
            <TableCell className="fw-bold">Categoria</TableCell>
            <TableCell className="fw-bold">Responsável</TableCell>
            {(estado === "em-processo" ||
              estado === "fechadas" ||
              estado === "urgentes") && (
              <TableCell className="fw-bold">Prazo</TableCell>
            )}
            <TableCell className="fw-bold" style={{ width: 100 }}>
              Acções
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {complaints
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((complaint) => (
              <TableRow
                key={complaint?.id}
                className={
                  estado !== "urgentes" && complaint?.urgente === "Sim"
                    ? "table-row-red"
                    : "table-row-simple"
                }
              >
                <TableCell component="th" scope="row" className="fw-bold">
                  {complaint?.ref || "---"}
                </TableCell>
                <TableCell>
                  {complaint?.vitima?.nome ||
                    complaint?.testemunha?.nome ||
                    "---"}
                </TableCell>
                <TableCell>
                  {complaint?.vitima?.telefone ||
                    complaint?.testemunha?.telefone ||
                    "---"}
                </TableCell>
                <TableCell>{complaint.provincia || "---"}</TableCell>
                <TableCell>{complaint?.tipo_denuncia || "---"}</TableCell>
                <TableCell>
                  {complaint?.utilizador?.nome
                    ? `${complaint?.utilizador?.nome} ${complaint?.utilizador?.sobrenome}`
                    : "---"}
                </TableCell>
                {(estado === "em-processo" ||
                  estado === "fechadas" ||
                  estado === "urgentes") && (
                  <TableCell
                    className={
                      formattDeadLine(complaint?.data_fim) &&
                      "text-danger fw-bold"
                    }
                  >
                    {complaint?.data_fim
                      ? dateFormat(complaint?.data_fim, "dd-mm-yyyy")
                      : "---"}
                  </TableCell>
                )}
                <TableCell>
                  <div className="row d-flex align-items-center">
                    <div className="dropdownrounded col-6">
                      <IconButton
                        id="dropdownMenu2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        size="small"
                        color="primary"
                        className="me-3"
                      >
                        <MoreVert />
                      </IconButton>
                      <ul
                        className="dropdown-menu shadow rounded"
                        aria-labelledby="dropdownMenu2"
                      >
                        {complaint?.urgente === "Não" && (
                          <li
                            onClick={() => {
                              setShowAddUrgency(true);
                              setSelectedComplaint(complaint);
                            }}
                            className="dropdown-item"
                          >
                            Marcar como urgente
                          </li>
                        )}
                        {complaint?.urgente === "Sim" && (
                          <li
                            onClick={() => {
                              setShowRemoveUrgency(true);
                              setSelectedComplaint(complaint);
                            }}
                            className="dropdown-item"
                          >
                            Retirar urgência
                          </li>
                        )}
                        {complaint?.status === "Pendente" && (
                          <li
                            onClick={() => {
                              setShowAprovar(true);
                              setSelectedComplaint(complaint);
                            }}
                            className="dropdown-item"
                          >
                            Aprovar
                          </li>
                        )}
                        {complaint?.status === "Aprovada" && (
                          <li
                            onClick={() => {
                              setShowProcessar(true);
                              setSelectedComplaint(complaint);
                            }}
                            className="dropdown-item"
                          >
                            Iniciar processo
                          </li>
                        )}
                        {(complaint?.status === "Aprovada" ||
                          complaint?.status === "Em processo") && (
                          <li
                            onClick={() => {
                              setShowFechar(true);
                              setSelectedComplaint(complaint);
                            }}
                            className="dropdown-item"
                          >
                            Fechar
                          </li>
                        )}{" "}
                        {complaint?.status === "Fechada" && (
                          <li
                            onClick={() => {
                              setShowReabrir(true);
                              setSelectedComplaint(complaint);
                            }}
                            className="dropdown-item"
                          >
                            Reabrir
                          </li>
                        )}
                        <Link to={`/editarDenuncia/${complaint.id}`}>
                          <li className="dropdown-item">Editar</li>
                        </Link>
                        <li
                          onClick={() => {
                            setShowEliminar(true);
                            setSelectedComplaint(complaint);
                          }}
                          className="dropdown-item"
                        >
                          Eliminar
                        </li>
                      </ul>
                    </div>
                    <div
                      hidden={complaint?.urgente !== "Sim"}
                      className="col-6"
                    >
                      <img
                        src={emergency}
                        alt="Urgente"
                        title="Caso urgente!"
                        style={{ width: 17 }}
                      />
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={complaints?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={"Linhas por página"}
      />
    </TableContainer>
  );
}
