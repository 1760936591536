import React from "react";
import AppbarDrawer from "../../components/drawer/drawer";
import Dashboard from "../../components/dashboard";



const AdminPag = () => {
  return (
    <div >
      <AppbarDrawer>
        <Dashboard/>
      </AppbarDrawer>
    </div>
  );
};
export default AdminPag;



