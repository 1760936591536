import firebase from "firebase/compat/app";
import "firebase/compat/storage";


const firebaseConfig = {
  apiKey: "AIzaSyD2W5yRE5_LNROjdNLE9Fe8ar2VV9Utq5c",
  authDomain: "hope-e36c8.firebaseapp.com",
  projectId: "hope-e36c8",
  storageBucket: "hope-e36c8.appspot.com",
  messagingSenderId: "273388790368",
  appId: "1:273388790368:web:6d40e95ba22bc52f56d905",
  measurementId: "G-RDFVD3B95V",
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };
